<template>
  <v-sheet class="py-6 px-8">
    <p class="text-h6 font-weight-bold grey--text text--darken-3 text-center mb-6">컨셉 수정하기</p>

    <v-sheet class="mt-2 px-4">
      <v-sheet v-if="!url" outlined class="rounded-10 pa-2 mt-2">
        <v-img :src="'/upload/concept/thumbnail_image/' + updateItem.thumbnail_image"></v-img>
      </v-sheet>

      <!-- 이미지 편집/미리보기 -->
      <v-sheet v-if="url" outlined class="rounded-10 pa-2 mt-2">
        <clipper-basic :src="url" ref="clipper" class="my-clipper" :ratio="4 / 2.5" :init-width="100">
          <div class="placeholder d-flex align-center justify-center" slot="placeholder">
            <span class="text-caption grey--text">이미지 미리보기</span>
          </div>
        </clipper-basic>
      </v-sheet>

      <!-- 이미지 첨부 -->
      <v-sheet class="mt-4 mb-4" @drop.prevent="addDropFile" @dragover.prevent>
        <v-file-input class="rounded-10" outlined dense show-size hide-details prepend-icon color="primary" label="이미지" v-model="uploadImage" @change="onFileChange()">
          <template v-slot:prepend-inner>
            <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
          </template>
        </v-file-input>
      </v-sheet>

      <div class="d-flex">
        <div style="width: 50%" class="mr-2">
          <v-sheet v-if="!url2" outlined class="rounded-10 pa-2 mt-2">
            <v-img :src="'/upload/concept/thumbnail_image/' + updateItem.pattern_1_image"></v-img>
          </v-sheet>

          <!-- 이미지 편집/미리보기 -->
          <v-sheet v-if="url2" outlined class="rounded-10 pa-2 mt-2">
            <clipper-basic :src="url2" ref="clipper2" class="my-clipper" :ratio="2.5 / 3" :init-width="100">
              <div class="placeholder d-flex align-center justify-center" slot="placeholder">
                <span class="text-caption grey--text">이미지 미리보기</span>
              </div>
            </clipper-basic>
          </v-sheet>

          <!-- 이미지 첨부 -->
          <v-sheet class="mt-4 mb-4" @drop.prevent="addDropFile2" @dragover.prevent>
            <v-file-input class="rounded-10" outlined dense show-size hide-details prepend-icon color="primary" label="패턴 1 이미지" v-model="uploadImage2" @change="onFileChange2()">
              <template v-slot:prepend-inner>
                <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
              </template>
            </v-file-input>
          </v-sheet>
        </div>
        <div style="width: 50%">
          <v-sheet v-if="!url3" outlined class="rounded-10 pa-2 mt-2">
            <v-img :src="'/upload/concept/thumbnail_image/' + updateItem.pattern_2_image"></v-img>
          </v-sheet>

          <!-- 이미지 편집/미리보기 -->
          <v-sheet v-if="url3" outlined class="rounded-10 pa-2 mt-2">
            <clipper-basic :src="url3" ref="clipper3" class="my-clipper" :ratio="2.5 / 3" :init-width="100">
              <div class="placeholder d-flex align-center justify-center" slot="placeholder">
                <span class="text-caption grey--text">이미지 미리보기</span>
              </div>
            </clipper-basic>
          </v-sheet>

          <!-- 이미지 첨부 -->
          <v-sheet class="mt-4 mb-4" @drop.prevent="addDropFile3" @dragover.prevent>
            <v-file-input class="rounded-10" outlined dense show-size hide-details prepend-icon color="primary" label="패턴 2 이미지" v-model="uploadImage3" @change="onFileChange3()">
              <template v-slot:prepend-inner>
                <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
              </template>
            </v-file-input>
          </v-sheet>
        </div>
      </div>

      <v-text-field class="rounded-10" label="제목(한글)" dense outlined v-model="updateItem.title"></v-text-field>
      <v-text-field class="rounded-10" label="제목(영어)" dense outlined v-model="updateItem.title_en"></v-text-field>
      <v-text-field class="rounded-10" label="제목(일어)" dense outlined v-model="updateItem.title_jp"></v-text-field>
      <v-autocomplete class="rounded-10" label="태그" dense outlined multiple :items="select_list.tag" v-model="updateItem.tag"></v-autocomplete>
      <v-text-field class="rounded-10" label="새도우" dense outlined v-model="updateItem.shadow_json"></v-text-field>
      <v-select :items="select_list.status" class="rounded-10" label="상태" dense outlined v-model="updateItem.status"></v-select>
      <v-text-field class="rounded-10" label="순서" dense outlined type="number" v-model="updateItem.order"></v-text-field>

      <!-- 본문 -->
      <v-divider></v-divider>
      <v-sheet>
        <div class="d-flex justify-space-between align-center">
          <div class="font-weight-bold text-h6 ml-4 mt-4">한글</div>
          <v-btn color="primary" depressed outlined small @click="clear('ko')">삭제</v-btn>
        </div>
        <TipTapWriter :key="componentKey" class="tiptap_style" :options="options" />
      </v-sheet>

      <v-sheet class="mt-4">
        <div class="d-flex justify-space-between align-center">
          <div class="font-weight-bold text-h6 ml-4 mt-4">영어</div>
          <v-btn color="primary" depressed outlined small @click="clear('en')">삭제</v-btn>
        </div>
        <TipTapWriter class="tiptap_style mt-2" :options="en.options" :key="'en' + en.componentKey" />
      </v-sheet>

      <v-sheet class="mt-4">
        <div class="d-flex justify-space-between align-center">
          <div class="font-weight-bold text-h6 ml-4 mt-4">일어</div>
          <v-btn color="primary" depressed outlined small @click="clear('jp')">삭제</v-btn>
        </div>
        <TipTapWriter class="tiptap_style mt-2" :options="jp.options" :key="'jp' + jp.componentKey" />
      </v-sheet>
    </v-sheet>
    <v-sheet class="d-flex justify-center mt-5">
      <v-btn class="rounded-10 px-6 mr-4" color="grey" large dark depressed @click="$emit('close')">
        <v-icon small left>mdi-cancel</v-icon>
        취소하기
      </v-btn>
      <v-btn class="rounded-10 px-6" color="primary" large dark depressed @click="updateSubmit()">
        <v-icon small left>mdi-database-edit-outline</v-icon>
        수정하기
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
import { AllTag, Tags } from "@/constant"

export default {
  props: ["updateItem_import"],

  components: {
    TipTapWriter,
  },

  data: () => ({
    select_list: {
      tag: Tags.map((e) => e.title),
      status: ["노출", "숨김"],
    },

    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    componentKey: 0,

    en: {
      //TipTap
      options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      componentKey: 0,
    },

    jp: {
      //TipTap
      options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      componentKey: 0,
    },

    uploadImage: {},
    url: "",

    uploadImage2: {},
    url2: "",

    uploadImage3: {},
    url3: "",

    updateItem: {
      pattern_1_image: "",
      pattern_2_image: "",
    },
  }),

  mounted() {
    this.updateItem = this.updateItem_import
    console.log(this.updateItem_import)
    if (this.updateItem_import.tag) {
      let test = this.updateItem_import.tag.split(",")
      this.updateItem.tag = test
    } else {
      this.updateItem.tag = ""
    }
    this.load()
  },

  watch: {
    updateItem_import() {
      this.updateItem = this.updateItem_import
      if (this.updateItem_import.tag) {
        let test = this.updateItem_import.tag.split(",")
        this.updateItem.tag = test
      } else {
        this.updateItem.tag = ""
      }
      this.load()
    },
  },

  methods: {
    load() {
      console.log("conceptId: " + this.updateItem.id)
      this.$http
        .post("/api/concept/select/specific", {
          params: {
            id: this.updateItem.id,
          },
        })
        .then((res) => {
          console.log(res.data)
          if (res.data && res.data.length > 0 && res.data[0].content) {
            this.options.content = res.data[0].content
            this.componentKey++

            this.en.options.content = res.data[0].content_en
            this.en.componentKey++

            this.jp.options.content = res.data[0].content_jp
            this.jp.componentKey++
          }
        })
    },

    // 파일 드래그앤드롭
    addDropFile(e) {
      const file = e.dataTransfer.files[0]
      this.uploadImage = e.dataTransfer.files[0]
      this.url = URL.createObjectURL(file)
    },

    // 파일 선택시 이미지 표시
    onFileChange() {
      const file = this.uploadImage
      this.url = URL.createObjectURL(file)
    },

    // 파일 드래그앤드롭
    addDropFile2(e) {
      const file = e.dataTransfer.files[0]
      this.uploadImage = e.dataTransfer.files[0]
      this.url2 = URL.createObjectURL(file)
    },

    // 파일 선택시 이미지 표시
    onFileChange2() {
      const file = this.uploadImage2
      this.url2 = URL.createObjectURL(file)
    },

    // 파일 드래그앤드롭
    addDropFile3(e) {
      const file = e.dataTransfer.files[0]
      this.uploadImage = e.dataTransfer.files[0]
      this.url3 = URL.createObjectURL(file)
    },

    // 파일 선택시 이미지 표시
    onFileChange3() {
      const file = this.uploadImage3
      this.url3 = URL.createObjectURL(file)
    },

    // 이미지 업로드
    imageUpload: _.debounce(async function () {
      // Crop 이미지 파일로 변환
      const canvas = this.$refs.clipper.clip() //call component's clip method
      var imgDataUrl = canvas.toDataURL("image/webp", 0.5) //canvas->image
      var binaryData = atob(imgDataUrl.split(",")[1])
      var array = []
      for (var i = 0; i < binaryData.length; i++) {
        array.push(binaryData.charCodeAt(i))
      }
      var file = new File([new Uint8Array(array)], "filename.png", { type: "image/png" })
      var formData = new FormData()
      formData.append("image", file)

      // 이미지 업로드
      await this.$http
        .post("/api/image/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            type: "concept/thumbnail_image",
          },
        })
        .then((res) => {
          this.updateItem.thumbnail_image = res.data
        })

      // Crop 이미지 파일로 변환
      const canvas2 = this.$refs.clipper2.clip() //call component's clip method
      var imgDataUrl = canvas2.toDataURL("image/webp", 0.5) //canvas->image
      var binaryData = atob(imgDataUrl.split(",")[1])
      var array = []
      for (var i = 0; i < binaryData.length; i++) {
        array.push(binaryData.charCodeAt(i))
      }
      var file = new File([new Uint8Array(array)], "filename.png", { type: "image/png" })
      var formData = new FormData()
      formData.append("image", file)

      // 이미지 업로드
      await this.$http
        .post("/api/image/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            type: "concept/thumbnail_image",
          },
        })
        .then((res) => {
          console.log(res)
          this.updateItem.pattern_1_image = res.data
        })

      // Crop 이미지 파일로 변환
      const canvas3 = this.$refs.clipper3.clip() //call component's clip method
      var imgDataUrl = canvas3.toDataURL("image/webp", 0.5) //canvas->image
      var binaryData = atob(imgDataUrl.split(",")[1])
      var array = []
      for (var i = 0; i < binaryData.length; i++) {
        array.push(binaryData.charCodeAt(i))
      }
      var file = new File([new Uint8Array(array)], "filename.png", { type: "image/png" })
      var formData = new FormData()
      formData.append("image", file)

      // 이미지 업로드
      await this.$http
        .post("/api/image/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            type: "concept/thumbnail_image",
          },
        })
        .then((res) => {
          console.log(res)
          this.updateItem.pattern_2_image = res.data
        })

      console.log(this.updateItem.status)

      // DB 입력
      await this.$http
        .post("/api/concept/update", {
          params: {
            thumbnail_image: this.updateItem.thumbnail_image,
            pattern_1_image: this.updateItem.pattern_1_image,
            pattern_2_image: this.updateItem.pattern_2_image,
            title: this.updateItem.title,
            title_en: this.updateItem.title_en,
            title_jp: this.updateItem.title_jp,
            content: this.options.content,
            content_en: this.en.options.content,
            content_jp: this.jp.options.content,
            tag: this.updateItem.tag.toString(),
            status: this.updateItem.status,
            id: this.updateItem.id,
            shadow_json: this.updateItem.shadow_json,
            order: Number(this.updateItem.order),
          },
        })
        .then((res) => {
          if (res.data.affectedRows) {
            alert("컨셉이 정상적으로 수정되었습니다")
            this.options.content == ""
            this.$emit("close")
          }
        })
    }, 500),

    // 목록
    async updateSubmit() {
      // 제목 검증
      if (this.updateItem.title == "") {
        alert("제목을 입력해주세요")
        return
      }

      // 컨셉소개 내용 검증
      if (this.options.content == "") {
        alert("컨셉소개 내용을 입력해주세요")
        return
      }

      // 태그 검증
      if (this.updateItem.tag.toString() == "") {
        alert("태그를 입력해주세요")
        return
      }

      // shadow 검증
      else if (this.updateItem.shadow_json == "") {
        alert("새도우값을 입력해주세요")
        return
      }

      // 상태 검증
      if (this.updateItem.status == "") {
        alert("상태를 입력해주세요")
        return
      }

      if (this.url == "" && this.url2 == "" && this.url3 == "") {
        // DB 입력
        this.$http
          .post("/api/concept/update", {
            params: {
              thumbnail_image: this.updateItem.thumbnail_image,
              pattern_1_image: this.updateItem.pattern_1_image,
              pattern_2_image: this.updateItem.pattern_2_image,
              title: this.updateItem.title,
              title_en: this.updateItem.title_en,
              title_jp: this.updateItem.title_jp,
              content: this.options.content,
              content_en: this.en.options.content,
              content_jp: this.jp.options.content,
              tag: this.updateItem.tag.toString(),
              status: this.updateItem.status,
              shadow_json: this.updateItem.shadow_json,
              order: this.updateItem.order,
              id: this.updateItem.id,
            },
          })
          .then((res) => {
            console.log(res)
            if (res.data.affectedRows) {
              alert("컨셉이 정상적으로 수정되었습니다")
              this.options.content == ""
              this.$emit("close")
            }
          })
      } else {
        await this.imageUpload()
      }
    },

    clear(lang = "ko") {
      if (lang === "ko") {
        this.options.content = ""
        this.componentKey++
      } else if (lang === "en") {
        this.en.options.content = ""
        this.en.componentKey++
      } else if (lang === "jp") {
        this.jp.options.content = ""
        this.jp.componentKey++
      }
    },
  },
}
</script>
<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
