<template>
  <v-app>
    <v-container>
      <!-- 결제 수단 : 카드 -->
      <v-card v-if="method == '카드'" outlined class="ma-5 pa-10">
        <h3 class="mb-4">{{ $t("page.pay.pay-card-method") }}</h3>
        <p v-html="$t('page.pay.pay-progress-text')"></p>
      </v-card>

      <!-- 결제 오류 -->
      <v-card v-if="error" outlined class="ma-5 pa-10">
        <h3 class="mb-4">{{ $t("page.pay.pay-error-title") }}</h3>
        <p>
          {{ $t("page.pay.pay-error-code") }} : {{ code }}<br />
          {{ $t("page.pay.pay-error-message") }}: {{ message }}
        </p>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex"

export default {
  data: () => ({
    error: false,
    method: "",
    code: "",
    message: "",
  }),

  mounted() {
    let amount = this.$route.query.amount
    let orderId = this.$route.query.orderId
    let paymentKey = this.$route.query.paymentKey

    this.doPayCompleted({
      orderNo: orderId,
      paymentKey,
      amount: Number(amount),
    })
      .then((res) => {
        console.log("결제 완료후 처리 성공")
      })
      .catch((e) => {
        console.log(e)
        alert(this.$t("alert.error_doing_pay_unknown_error"))
      })
  },

  methods: {
    ...mapActions(["doPayCompleted"]),
  },
}
</script>
