<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">프로모션 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 검색 -->
      <v-sheet outlined class="pa-2 mb-2 d-flex">
        <v-sheet class="d-flex align-center" width="800">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="select_list.search_type" v-model="search_type"></v-select>
          </v-sheet>

          <!-- 키워드 -->
          <v-text-field placeholder="검색어를 입력해주세요" outlined dense hide-details v-model="keyword" @keyup.enter="search()"></v-text-field>

          <!-- 제출 버튼 -->
          <v-btn height="40" class="px-6 ml-3" color="primary" dark depressed @click="search()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>

          <!-- 활성화 여부 체크 박스-->
          <v-checkbox v-model="isEnabled" class="shrink mt-0 ml-4" label="활성화만" color="primary" hide-details dense> 활성화 상태만</v-checkbox>
        </v-sheet>
      </v-sheet>

      <!-- 목록 -->
      <v-radio-group v-model="selected" hide-details>
        <v-data-table
          hide-default-header
          :items="list"
          :footer-props="{
            'items-per-page-options': [10, 20, 100, -1],
            itemsPerPageText: '페이지당 아이템수',
          }"
          no-data-text=""
        >
          <template v-slot:header>
            <tr>
              <th style="width: 52px"></th>
              <th style="width: 100px">이름</th>
              <th style="width: 150px">코드</th>
              <th style="width: 50px">활성화여부</th>
              <th style="width: 100px">솔로샷(KRW)</th>
              <th style="width: 100px">솔로샷 듀얼(KRW)</th>
              <th style="width: 100px">그룹샷(KRW)</th>
              <th style="width: 100px">2인할인팩(KRW)</th>
              <th style="width: 100px">3인할인팩(KRW)</th>
              <th style="width: 100px">4인할인팩(KRW)</th>
              <th style="width: 100px">컨셉</th>
              <th style="width: 190px">프로모션 유효기간</th>
              <th style="width: 190px">예약가능 기간</th>
              <th style="width: 150px">생성일</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-radio class="ml-1" hide-details dense :value="item" @change="selectedItem = item"></v-radio>
              </td>
              <td class="text-truncate">
                {{ item.name }}
              </td>
              <td class="text-truncate">
                {{ item.code }}
              </td>
              <td class="text-truncate">
                {{ item.enabled }}
              </td>
              <td class="text-truncate">{{ $toComma(item.option1Price) }} 원</td>
              <td class="text-truncate">{{ $toComma(item.option2Price) }} 원</td>
              <td class="text-truncate">{{ $toComma(item.option3Price) }} 원</td>
              <td class="text-truncate">{{ $toComma(item.option4Price) }} 원</td>
              <td class="text-truncate">{{ $toComma(item.option5Price) }} 원</td>
              <td class="text-truncate">{{ $toComma(item.option6Price) }} 원</td>
              <td class="text-truncate">{{ getConceptTitle(item.conceptId) }}</td>
              <td class="text-truncate">
                {{ item.openAt ? dayjs(new Date(item.openAt)).format("YYYY-MM-DD") : "" }}
                {{ item.closeAt ? " ~ " + dayjs(new Date(item.closeAt)).format("YYYY-MM-DD") : "" }}
              </td>
              <td class="text-truncate">
                {{ item.startAt ? dayjs(new Date(item.startAt)).format("YYYY-MM-DD") : "" }}
                {{ " ~ " + dayjs(new Date(item.endAt)).format("YYYY-MM-DD") }}
              </td>
              <td class="text-truncate text-caption">
                {{ dayjs(new Date(item.created)).format("YYYY-MM-DD") }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-radio-group>

      <!-- 버튼 -->
      <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
        <v-btn class="px-6 ml-3" color="primary" large dark depressed @click="openUrl()" outlined>
          <v-icon small left>mdi-open-in-new</v-icon>
          OPEN LINK
        </v-btn>
        <v-btn class="px-6 ml-3" color="green" large dark depressed @click="updateOpen()">
          <v-icon small left>mdi-database-edit-outline</v-icon>
          수정하기
        </v-btn>

        <v-btn class="px-6 ml-3" color="blue" large dark depressed @click="createNewConcept()">
          <v-icon small left>mdi-database-plus-outline</v-icon>
          추가하기
        </v-btn>
        <v-btn class="px-6 ml-3" color="red" large dark depressed @click="deleteItem()">
          <v-icon small left>mdi-database-remove-outline</v-icon>
          삭제하기
        </v-btn>
      </v-card>
    </v-sheet>

    <!-- 프로모션 수정하기 -->
    <v-dialog v-model="dialog.update" width="660" content-class="rounded-xl" persistent>
      <UpdatePromotion :updateItem_import="selectedItem" @close="close_handler" :key="componentKey" />
    </v-dialog>

    <!-- 프로모션 추가하기 -->
    <v-dialog v-model="dialog.create" width="660" content-class="rounded-xl" persistent>
      <InsertPromotion @close="close_handler" :key="componentCreateKey" />
    </v-dialog>
  </div>
</template>
<script>
import { computed } from "vue"
import InsertPromotion from "./promotion/InsertPromotion"
import UpdatePromotion from "./promotion/UpdatePromotion"
import { mapActions, mapState } from "vuex"

export default {
  components: {
    InsertPromotion,
    UpdatePromotion,
  },

  provide() {
    return {
      coupons_info: computed(() => this.couponInfos),
      concepts: computed(() => this.concepts),
    }
  },

  data: () => ({
    select_list: {
      search_type: ["이름", "코드"],
    },

    search_type: "이름",
    keyword: "",
    isEnabled: true,

    list: [],
    couponInfos: [],

    selected: false,
    selectedItem: {},

    componentKey: 0,
    componentCreateKey: 0,

    dialog: {
      create: false,
      update: false,
    },
    concepts: [],

    visibleCouponSelect: false,
  }),

  created() {},

  async mounted() {
    try {
      this.concepts = await this.loadConceptsAll()
    } catch (e) {}

    // 목록
    this.search()
  },

  watch: {
    selectedItem() {
      console.log(this.selectedItem)
      this.componentKey++
    },

    isEnabled() {
      this.search()
    },
  },

  methods: {
    ...mapActions(["loadConceptsAll"]),
    // 목록
    async search() {
      console.log("search", this.search_type, this.keyword)

      const coupons_res = await this.$http("/api/coupon/getCouponList/search", {
        params: {
          enabled: true,
        },
      })
      this.couponInfos = coupons_res.data

      this.$http
        .post("/api/promotion/select/admin/search", {
          params: {
            type: this.search_type,
            keyword: this.keyword,
            enabled: this.isEnabled,
            isNotTraveler: true,
          },
        })
        .then((res) => {
          this.list = res.data.map((item) => {
            let couponArr = item.coupons ? item.coupons.split(",") : []
            return {
              ...item,
              regCoupons: couponArr.map((co) => {
                return {
                  id: Number(co.trim()),
                  title: this.getCouponName(co),
                }
              }),
            }
          })
        })
        .catch((e) => {
          alert(this.$t("alert.error_page"))
        })
    },
    // 닫기 핸들러
    close_handler() {
      this.dialog.create = false
      this.dialog.update = false
      this.selected = false
      this.selectedItem = {}
      this.search()
    },

    createNewConcept() {
      this.dialog.create = true
      this.componentCreateKey++
    },

    // 삭제하기
    deleteItem() {
      if (!this.selectedItem || !this.selectedItem.id) {
        alert("선택한 항목이 없습니다")
      } else {
        if (confirm("정말 해당 아이템을 삭제하겠습니까?")) {
          this.$http
            .post("/api/promotion/delete/id", {
              params: {
                id: this.selectedItem.id,
              },
            })
            .then(async (res) => {
              if (res.data.affectedRows) {
                try {
                  await this.$http.post("/api/promotion/coupons/delete", {
                    packageCode: this.selectedItem.code,
                  })
                } catch (e) {
                  this.$toast.error("자동 발급 쿠폰이 삭제 되지 않았습니다.")
                }
                this.search()
                this.selected = false
                this.selectedItem = {}
              } else {
                this.search()
                this.selected = false
                this.selectedItem = {}
                alert("정상적으로 삭제가 되지 않았습니다.")
              }
            })
        }
      }
    },

    // update Open
    updateOpen() {
      if (!this.selectedItem || !this.selectedItem.id) {
        alert("선택한 아이템이 없습니다")
      } else {
        this.componentKey++
        this.dialog.update = true
      }
    },

    openUrl() {
      if (!this.selectedItem || !this.selectedItem.id) {
        alert("선택한 아이템이 없습니다")
      } else {
        let url = window.location.origin + "/reservation/buy?event=" + this.selectedItem.code
        window.open(url, "_blank")
      }
    },

    showCouponSelct() {
      this.visibleCouponSelect = true
    },

    getCouponName(id) {
      let couponId = Number(id)
      return this.couponInfos.find((i) => i.id === couponId).title
    },

    getConceptTitle(conceptId) {
      return this.concepts.find((e) => e.id === conceptId)?.title || conceptId
    },
  },
}
</script>
