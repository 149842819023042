import axios from "@/api/restApi"

/// 통계 데이터 조회
export default {
  async getSaleStats(isMonth, from, to) {
    const res = await axios.get("/api/stats/getSaleStats", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },

  async getSaleStatsV2(isMonth, from, to) {
    const res = await axios.get("/api/stats/getSaleStatsV2", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },

  async getUserStats(isMonth, from, to) {
    const res = await axios.get("/api/stats/getUserStats", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },

  async getUserCount() {
    const res = await axios.get("/api/user/select/count")
    return res.data.totalCount
  },

  async getVisitorStats(isMonth, from, to) {
    const res = await axios.get("/api/stats/getVisitorStats", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },

  async getPhotoShootStats(isMonth, from, to) {
    const res = await axios.get("/api/stats/getPhotoShootStats", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },

  async getContentsStats(isMonth, from, to) {
    const res = await axios.get("/api/stats/getContentsStats", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },

  async getReviewStats(isMonth, from, to) {
    const res = await axios.get("/api/stats/getReviewStats", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },

  async getReviewCount() {
    const res = await axios.get("/api/review/select/all")
    return res.data.totalCount
  },

  async getReviewDetailStats(from, to) {
    const res = await axios.get("/api/stats/getReviewDetailStats", {
      params: {
        from,
        to,
      },
    })
    return res.data
  },

  async getVisitTimeStats(isMonth, from, to) {
    const res = await axios.get("/api/stats/getVisitTimeStats", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },
  async getSalesPerVisitorStats(isMonth, from, to) {
    const res = await axios.get("/api/stats/getSalesPerVisitorStats", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },
  async getRevisitStats(isMonth, from, to) {
    const res = await axios.get("/api/stats/getRevisitStats", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },

  async getSalesStatsEachType(isMonth, from, to) {
    const res = await axios.get("/api/stats/getSaleStatsDetail", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    const data = res.data
    return Object.groupBy(data, ({ date }) => date)
  },

  async getSalesStatsDetail(from, to) {
    const res = await axios.get("/api/stats/getSaleStatsDetailV2", {
      params: {
        from,
        to,
      },
    })
    const data = res.data
    return Object.groupBy(data, ({ date }) => date)
  },

  async getPromotionSalesStatsWithPayedDate(isMonth, from, to, promotionCode) {
    const res = await axios.get("/api/stats/getPromotionSaleStatsWithPayedDate", {
      params: {
        isMonth,
        from,
        to,
        promotionCode,
      },
    })
    return res.data
  },

  async getPromotionSalesStats(isMonth, from, to, promotionCode) {
    const res = await axios.get("/api/stats/getPromotionSaleStats", {
      params: {
        isMonth,
        from,
        to,
        promotionCode,
      },
    })
    return res.data
  },

  async getDressVistorStats(isMonth, from, to) {
    const res = await axios.get("/api/stats/getVisitorStats", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },

  async getPhotoShootContentsStats(isMonth, from, to) {
    const res = await axios.get("/api/stats/getPhotoShootContentsStats", {
      params: {
        isMonth,
        from,
        to,
      },
    })
    return res.data
  },

  async getReservationStats(from, to) {
    const res = await axios.get("/api/stats/getReservationStats", {
      params: {
        from,
        to,
      },
    })
    return res.data
  },
}
