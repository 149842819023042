<template>
  <div>
    <!-- 본문 -->
    <v-sheet class="pt-4 mx-auto">
      <!-- 내 사진 내역 없을 시 -->
      <v-sheet v-if="!list.length" class="rounded-10 pt-8 text-center text-subtitle-1 primary--text">
        <v-icon size="40" color="primary" class="mb-2">mdi-cancel</v-icon><br />
        {{ $t("page.mypage.album.empty") }}
      </v-sheet>

      <!-- 내 사진 내역 있을 시 -->
      <v-sheet v-if="list.length" class="py-1">
        <v-row no-gutters>
          <v-col v-for="item in list" :key="item.filename" cols="4" style="border: 1px solid white; cursor: pointer">
            <v-img
              width="100%"
              aspect-ratio="0.8"
              :src="item.thumbnailUrl"
              @click="openDownload(item)"
              :gradient="item.completeStatus == '완료예정' ? 'rgba(92,92,92,.8),rgba(92,92,92,.8)' : ''"
              :style="item.completeStatus === '완료' ? 'cursor: cursor' : 'cursor: default'"
            >
              <div class="sela-bottom-caption d-flex justify-center align-center" :class="item.status != 2 ? 'disabled' : ''">
                <div style="color: white; position: absolute; bottom: 8px; font-size: 0.8rem; text-align: center">
                  {{ getTitle(item.title) }}
                  <br />
                  {{ getDispText(item.completeStatus) }} {{ item.completeStatus === "완료예정" ? item.completeDate : "" }}
                </div>
              </div>
            </v-img>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script>
import { fetchMyPictureItemList } from "@/models/MyPicture"
import { mapActions, mapState } from "vuex"

export default {
  data: () => ({
    list: [],
  }),

  mounted() {
    this.load()
  },

  computed: {
    ...mapState(["lang", "concepts"]),
  },

  methods: {
    load() {
      fetchMyPictureItemList(this, this.$store.state.lafia_user.user_id).then(({ data, headers }) => {
        this.list = data.reverse()
      })
    },

    openDownload(item) {
      if (item.processStatus == "effect") {
        // TODO: 후보정 안내문구 팝업 필요?
        return
      }
      if (item.completeStatus !== "완료") {
        return
      }

      this.$router.push(`/mypage/picDetail/${item.id}`)
    },

    getDispText(status) {
      if (this.lang === "ko") return status
      switch (status) {
        case "완료":
          return this.lang === "jp" ? "完了" : "Completed"
        case "완료예정":
          return this.lang === "jp" ? "完了予定" : "Expected"
        default:
          return status
      }
    },
    getTitle(conceptTitleKr) {
      const concept = this.concepts.find((c) => c.title === conceptTitleKr)
      switch (this.lang) {
        case "en":
          return (concept && concept.title_en) || conceptTitleKr
          break
        case "jp":
          return (concept && concept.title_jp) || conceptTitleKr

        default:
          return conceptTitleKr
      }
    },
  },
}
</script>
<style scoped></style>
