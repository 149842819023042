<template>
  <div class="ma-2">
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">라피아 운영 시간 설정</font>
    </v-sheet>

    <v-sheet outlined class="pa-8" :key="componentKey">
      <div v-for="(item, index) in dayItems" :key="item">
        <div>{{ item }}</div>
        <week-time-component :key="'week-time' + index" @change="(v) => setOperationTime(index, v)" :src="settings[index]" />
      </div>
    </v-sheet>

    <!-- 버튼 -->
    <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
      <v-btn class="px-6 ml-3" color="blue" large dark depressed @click="submit()">
        <v-icon small left>mdi-database-edit-outline</v-icon>
        {{ "저장" }}
      </v-btn>
    </v-card>
  </div>
</template>
<script>
import WeekTimeComponent from "./WeekTimeComponent"

export default {
  components: {
    WeekTimeComponent,
  },
  data: () => ({
    info: null,
    settings: [],
    dayItems: ["월", "화", "수", "목", "금", "토", "일"],
    error: null,
    componentKey: 0,
  }),

  created() {
    this.settings[0] = []
    this.settings[1] = []
    this.settings[2] = []
    this.settings[3] = []
    this.settings[4] = []
    this.settings[5] = []
    this.settings[6] = []
  },

  mounted() {
    this.load()
  },

  methods: {
    load() {
      this.$http
        .get("/api/operation_time/select")
        .then((res) => {
          if (res.data && res.data.length) {
            this.info = res.data[0]
            this.settings[0] = this.info.mon.split(",").map((e) => e.trim())
            this.settings[1] = this.info.tue.split(",").map((e) => e.trim())
            this.settings[2] = this.info.wed.split(",").map((e) => e.trim())
            this.settings[3] = this.info.thu.split(",").map((e) => e.trim())
            this.settings[4] = this.info.fri.split(",").map((e) => e.trim())
            this.settings[5] = this.info.sat.split(",").map((e) => e.trim())
            this.settings[6] = this.info.sun.split(",").map((e) => e.trim())
            this.componentKey++
          }
        })
        .catch((e) => {
          this.error = e
        })
    },
    setOperationTime(index, value) {
      this.settings[index] = value
    },
    submit: _.debounce(async function () {
      console.log("submit", this.settings)
      if (this.error) {
        alert("정보를 가져오지 못했습니다. 다시 시도해 주세요.")
        this.$router.go(0)
        return
      }
      try {
        if (this.info && this.info.length) {
          await this.updateOperationTime()
        } else {
          await this.insertOperationTime()
        }
        alert("저장하였습니다.")
      } catch (e) {
        console.log(e)
        alert("저장에 실패하였습니다.")
      }
    }, 500),

    async insertOperationTime() {
      const res = await this.$http.post("/api/operation_time/insert", {
        mon: this.settings[0].length > 0 ? this.settings[0].join(",") : "",
        tue: this.settings[1].length > 0 ? this.settings[1].join(",") : "",
        wed: this.settings[2].length > 0 ? this.settings[2].join(",") : "",
        thu: this.settings[3].length > 0 ? this.settings[3].join(",") : "",
        fri: this.settings[4].length > 0 ? this.settings[4].join(",") : "",
        sat: this.settings[5].length > 0 ? this.settings[5].join(",") : "",
        sun: this.settings[6].length > 0 ? this.settings[6].join(",") : "",
      })
      return res
    },

    async updateOperationTime() {
      const res = await this.$http.post("/api/operation_time/update", {
        mon: this.settings[0].length > 0 ? this.settings[0].join(",") : "",
        tue: this.settings[1].length > 0 ? this.settings[1].join(",") : "",
        wed: this.settings[2].length > 0 ? this.settings[2].join(",") : "",
        thu: this.settings[3].length > 0 ? this.settings[3].join(",") : "",
        fri: this.settings[4].length > 0 ? this.settings[4].join(",") : "",
        sat: this.settings[5].length > 0 ? this.settings[5].join(",") : "",
        sun: this.settings[6].length > 0 ? this.settings[6].join(",") : "",
        id: this.info.id,
      })
      return res
    },
  },
}
</script>
<style></style>
