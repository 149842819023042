<template>
  <div>
    <div class="py-8">
      <v-sheet class="py-4 px-4 mx-auto rounded-lg text-center" width="80%" outlined>
        <!-- 로고 & 문구 -->
        <div class="d-flex mb-2 mx-auto">
          <v-img height="40" width="140" contain src="@/assets/logo/main.svg"></v-img>
        </div>
        <p class="mb-6 text-subtitle-1 font-weight-medium grey--text text--darken-2">{{ $t("page.login.find-id-title") }}</p>
        <v-sheet :width="$vuetify.breakpoint.mobile ? '94%' : 280" class="mx-auto">
          <!-- 입력 -->
          <v-text-field outlined v-model.trim="name" readonly hide-details v-model="$route.query.userId"> </v-text-field>

          <p class="mt-4 mb-8 text-caption text-center">{{ $t("page.findId.founded-id-success-text") }}</p>

          <!-- 발송 -->
          <div class="mt-4">
            <v-btn width="100%" class="font-weight-bold mt-2" color="primary" dark large depressed tp="/auth/login"> {{ $t("button.login") }} </v-btn>
            <v-btn class="mt-2" color="grey darken-1" text small to="/auth/find/password"> {{ $t("page.login.reset-password") }} </v-btn>
          </div>
        </v-sheet>

        <v-dialog max-width="440" v-model="show_warn_dialog" content-class="rounded-10" style="z-index: 999">
          <v-sheet class="rounded-10 pa-4">
            <p class="text-center mb-0">
              <v-icon size="80" color="#f2b8bf" class="">mdi-alert-circle-outline</v-icon>
            </p>

            <p class="text-center text-body mt-3 px-1" style="letter-spacing: 0px !important">
              {{ warn_error_message }}
            </p>

            <div class="d-flex justify-center mt-6">
              <v-btn color="#f2b8bf" class="rounded-10" dark width="48%" depressed @click="warn_error_message = ''"> {{ $t("button.ok") }} </v-btn>
            </div>
          </v-sheet>
        </v-dialog>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate"
import { required, sameAs, minLength, email } from "vuelidate/lib/validators"
import authService from "@/api/auth-service"

export default {
  data: () => ({
    warn_error_message: "",
  }),

  methods: {
    login() {
      this.$router.replace("/auth/login")
    },
  },
}
</script>
<style></style>
