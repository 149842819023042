<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">예약 수동 등록</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2" min-height="500px">
      <form>
        <v-row>
          <v-sheet width="300px" class="ma-4">
            <div class="py-4 px-2 text-body-1 font-weight-medium">예약 날짜</div>
            <!-- 날짜 선택 -->
            <v-date-picker v-model="pickerDate" color="primary lighten-1" header-color="primary" locale="ko"></v-date-picker>
          </v-sheet>
          <v-sheet class="ma-4" width="400px">
            <v-sheet width="400px" class="mt-8">
              <div class="text-body-1 font-weight-bold">시간 선택</div>
              <!-- 시간 선택 -->
              <div class="d-flex justify-start flex-wrap mt-4" style="width: 340px; gap: 6px" :key="timeSelectcomponentKey + 'time'">
                <v-btn
                  v-for="item in time_option_list"
                  :key="item"
                  height="36"
                  depressed
                  class="timeSelectBtn rounded-10 px-2 mb-1 my-1"
                  :class="{ enable: selected_time == item }"
                  @click="selected_time != item ? (selected_time = item) : (selected_time = '')"
                >
                  {{ item }}
                </v-btn>
              </div>
            </v-sheet>
            <v-sheet width="300px" class="mt-8">
              <v-select outlined :items="option_list" label="옵션 선택" v-model="selected_option" required></v-select>
            </v-sheet>
            <div class="align-center text-body-1" :class="selected_option !== 'option3' ? 'd-none' : 'd-flex'">
              {{ $t("page.reservation.people-number") }}
              <v-select
                style="width: 72px; margin: 0 6px; z-index: 5"
                class="rounded-10 shrink my-0"
                hide-details
                outlined
                :disabled="selected_option !== 'option3'"
                dense
                append-icon="mdi-chevron-down"
                :items="persons_select_list"
                v-model.number="persons"
                :key="personComponentKey + 'person'"
                @change="(e) => $emit('changeCount', e)"
                required
              >
                <template v-slot:selection="{ item }">
                  <span class="pl-1 pb-2px">{{ item }}</span>
                </template>
                <template v-slot:append>
                  <v-icon style="width: 12px">mdi-chevron-down</v-icon>
                </template>
              </v-select>
              {{ $t("page.reservation.people-unit") }}
            </div>

            <v-sheet width="300px" class="mt-6">
              <v-select outlined :items="concepts" item-text="title" v-model="selectedConcept" label="컨셉" :key="'concept' + conceptComponentKey" required return-object></v-select>
            </v-sheet>
          </v-sheet>
          <v-sheet width="400px" class="mx-4 mt-6">
            <p class="mb-6 font-weight-medium text-center" style="font-size: 20px">메모</p>

            <v-textarea class="rounded-15 flex-grow-1" outlined v-model="memo" counter="512" maxlength="512" height="300"></v-textarea>
          </v-sheet>
        </v-row>

        <!-- 버튼 -->
        <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
          <v-btn class="px-6 ml-3" color="blue" large dark depressed @click="submit" :loading="saving">
            <v-icon small left>mdi-database-plus-outline</v-icon>
            추가하기
          </v-btn>
        </v-card>
      </form>
    </v-sheet>
  </div>
</template>
<script>
import { OperationTimeList } from "@/constant"
import { mapActions, mapState } from "vuex"

export default {
  data: () => ({
    pickerDate: null,

    time_option_list: OperationTimeList,
    selected_time: "",
    timeSelectcomponentKey: 0,

    option_list: [
      {
        text: "솔로샷",
        value: "option1",
      },
      {
        text: "솔로샷 듀얼",
        value: "option2",
      },
      {
        text: "그룹샷",
        value: "option3",
      },
      {
        text: "2인할인팩",
        value: "option4",
      },
      {
        text: "3인할인팩",
        value: "option5",
      },
      {
        text: "4인할인팩",
        value: "option6",
      },
    ],
    selected_option: "option1",

    personComponentKey: 0,
    persons_select_list: [2, 3, 4],
    persons: 2,

    selectedConcept: null,
    conceptComponentKey: 0,

    memo: "",

    concepts: [],

    saving: false,
  }),

  computed: {
    ...mapState(["default_price"]),
  },

  created() {
    this.pickerDate = this.dayjs().format("YYYY-MM-DD")
  },

  async mounted() {
    try {
      this.concepts = await this.loadConceptsAll()
    } catch (e) {}
  },

  methods: {
    ...mapActions(["loadConceptsAll"]),
    submit: _.debounce(async function () {
      if (!this.selected_time) {
        alert("시간을 선택해 주세요")
        return
      } else if (!this.selectedConcept) {
        alert("컨셉을 선택해 주세요")
        return
      }
      console.log(this.selectedConcept)
      try {
        this.saving = true

        let orderId = Math.floor(Math.random() * 1000000000)

        let orderCount = this.selected_option === "option3" ? this.persons : 1

        let price = 0
        switch (this.selected_option) {
          case "option1":
            price = this.default_price.option1Price
            break
          case "option2":
            price = this.default_price.option2Price
            break
          case "option3":
            price = this.default_price.option3Price
            break
        }

        let orderList = [
          {
            productName: "라피아 이용권",
            orderNum: orderCount,
            price: price,
            totalPrice: orderCount * price,
            finalPrice: 0,
          },
        ]

        const orderRes = await this.$http.post("/api/order/v2/order", {
          orderId: orderId.toString(),
          userId: "support@rocketworks.io",
          orderDetailList: orderList,
          memo: this.selected_option,
          type: "현장이용권",
          isSite: true,
        })

        let params = {
          order_id: orderRes.data.id,
          user_id: "support@rocketworks.io",
          category: "라피아캐슬",
          maker: null,
          concept: this.selectedConcept.title,
          year: this.dayjs(this.pickerDate).year(),
          month: this.dayjs(this.pickerDate).month() + 1,
          day: this.dayjs(this.pickerDate).date(),
          time: this.selected_time,
          cloth: orderCount,
          clothPrice: 0,
          uncloth: 0,
          unclothPrice: 0,
          memo: this.memo,
        }
        // 시간예약 입력하기
        await this.$http.post("/api/reservation/v2/reserve", {
          ...params,
        })

        alert("예약 등록 완료하였습니다.")
      } catch (e) {
        console.log(e)
        alert("예약 등록에 실패하였습니다.")
      } finally {
        this.saving = false
      }
    }, 500),
  },
}
</script>
<style scoped>
.timeSelectBtn {
  border: 1px solid lightgray;
  color: #515151;
  font-size: 16px;
  font-weight: normal;
  margin: 0 3px;
  background-color: white !important;
}

.enable {
  background: #ef7fab !important;
  color: white !important;
}
</style>
