<template>
  <div>
    <v-sheet class="d-flex flex-wrap" style="gap: 16px" :key="componentKey">
      <v-checkbox v-for="item in time_option_list" v-model="selected" :key="item" :label="item" :value="item" @change="(v) => $emit('change', v)"></v-checkbox>
    </v-sheet>
  </div>
</template>
<script>
import { OperationTimeList } from "@/constant"
export default {
  props: ["src"],

  data: () => ({
    selected: [],
    time_option_list: OperationTimeList,
    componentKey: 0,
  }),

  created() {
    console.log(this.src)
    this.selected = this.src
    this.componentKey++
  },
}
</script>
<style></style>
