<template>
  <div>
    <v-sheet outlined class="pa-2 ma-2 d-flex align-center">
      <div>
        <!-- title -->
        <span class="primary--text font-weight-bold"> {{ dayjs().year() }}년</span>
        <span class="font-weight-bold mx-2">총 예약</span>
        <span class="text-h6 mx-4"> {{ $toComma(totalAmount) }} 건</span>
      </div>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2" width="1200">
      <v-data-table :headers="listTableHeaders" :items="list_items" hide-default-footer :items-per-page="-1" :loading="isLoading" loading-text="Loading... Please wait">
        <template v-slot:[`item.month`]="{ item }">
          <span>{{ Number(item.month) }}월</span>
        </template>
        <template v-slot:[`item.total_sales`]="{ item }">
          <span>{{ $toComma(Number(item.total_sales)) }}</span>
        </template>
        <template v-slot:[`item.avg_sales_per_reservation`]="{ item }">
          <span>{{ $toComma(Math.floor(Number(item.avg_sales_per_reservation))) }}</span>
        </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>
<script>
import statusService from "@/api/stats-service"
export default {
  data: () => ({
    isLoading: false,
    list_items: [],
    totalAmount: 0,
    listTableHeaders: [
      { value: "month", text: "월" },
      { value: "option1_count", text: "솔로", align: "right" },
      { value: "option2_count", text: "솔로 듀얼", align: "right" },
      { value: "option3_count", text: "그룹", align: "right" },
      { value: "option4_count", text: "2인 할인 팩", align: "right" },
      { value: "option5_count", text: "3인 할인 팩", align: "right" },
      { value: "option6_count", text: "4인 할인 팩", align: "right" },
      { value: "total_reservations", text: "총 예약 건", align: "right" },
      { value: "total_sales", text: "총 매출", align: "right" },
      { value: "avg_sales_per_reservation", text: "예약 단가", align: "right" },
    ],
  }),
  created() {},
  mounted() {
    this.loadList()
  },
  methods: {
    async loadList() {
      try {
        this.isLoading = true

        let from = this.dayjs().startOf("year").format("YYYY-MM-DD")
        let to = this.dayjs().add(3, "month").endOf("month").format("YYYY-MM-DD")

        const result = await statusService.getReservationStats(from, to)
        this.list_items = result

        this.totalAmount = result.reduce((sum, e) => sum + Number(e.total_reservations), 0)
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style scoped>
.greyBg {
  background-color: rgb(220, 220, 220);
}

.accentBg {
  background-color: rgb(220, 182, 220);
}
</style>
