import { render, staticRenderFns } from "./ConceptSelectDialog.vue?vue&type=template&id=b993880c&scoped=true"
import script from "./ConceptSelectDialog.vue?vue&type=script&lang=js"
export * from "./ConceptSelectDialog.vue?vue&type=script&lang=js"
import style0 from "./ConceptSelectDialog.vue?vue&type=style&index=0&id=b993880c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b993880c",
  null
  
)

export default component.exports