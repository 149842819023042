<template>
  <v-sheet class="py-6 px-8">
    <p class="text-h6 font-weight-bold grey--text text--darken-3 text-center mb-6">프로모션 추가하기</p>

    <v-sheet class="mt-2 px-4">
      <v-text-field class="rounded-10" label="이름" dense outlined v-model.trim="create.name"></v-text-field>
      <v-text-field class="rounded-10" label="코드" dense outlined v-model.trim="create.code"></v-text-field>
      <v-text-field class="rounded-10" label="솔로샷(KRW)" dense outlined type="number" hide-spin-buttons v-model="create.option1Price"></v-text-field>
      <v-text-field class="rounded-10" label="솔로샷 듀얼(KRW)" dense outlined type="number" hide-spin-buttons v-model="create.option2Price"></v-text-field>
      <v-text-field class="rounded-10" label="그룹샷(KRW)" dense outlined type="number" hide-spin-buttons v-model="create.option3Price"></v-text-field>
      <v-text-field class="rounded-10" label="2인할인팩(KRW)" dense outlined type="number" hide-spin-buttons v-model="create.option4Price"></v-text-field>
      <v-text-field class="rounded-10" label="3인할인팩(KRW)" dense outlined type="number" hide-spin-buttons v-model="create.option5Price"></v-text-field>
      <v-text-field class="rounded-10" label="4인할인팩(KRW)" dense outlined type="number" hide-spin-buttons v-model="create.option6Price"></v-text-field>
      <v-select outlined dense :items="conceptList" item-text="title" item-value="id" v-model="create.conceptId" label="컨셉" :key="'concept' + conceptComponentKey"></v-select>
      <div class="font-weight-bold text-body-1 py-2">프로모션 유효기간</div>
      <v-row>
        <v-menu v-model="openAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="create.openAtDate" label="링크시작일" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green"></v-text-field>
          </template>
          <v-date-picker v-model="create.openAtDate" no-title scrollable color="green" @input="openAtMenu = false"></v-date-picker>
        </v-menu>
        <v-menu v-model="closeAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="create.closeAtDate" label="링크종료일" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning"></v-text-field>
          </template>
          <v-date-picker v-model="create.closeAtDate" no-title scrollable color="warning" @input="closeAtMenu = false"> </v-date-picker>
        </v-menu>
      </v-row>

      <div class="font-weight-bold text-body-1 py-2">예약 가능 기간</div>
      <v-row>
        <v-menu v-model="startAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="create.startAtDate" label="예약 시작일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green"></v-text-field>
          </template>
          <v-date-picker v-model="create.startAtDate" no-title scrollable color="green" @input="startAtMenu = false"></v-date-picker>
        </v-menu>
        <v-menu v-model="endAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="create.endAtDate" label="예약 종료일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning"></v-text-field>
          </template>
          <v-date-picker v-model="create.endAtDate" no-title scrollable color="warning" @input="endAtMenu = false"> </v-date-picker>
        </v-menu>
      </v-row>

      <div class="font-weight-bold text-h6 ml-4 mt-4">한글</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear()">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="options" :key="'desc1' + componentKey" />
      </v-sheet>

      <div class="font-weight-bold text-h6 ml-4 mt-4">영어</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear('en')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="en.options" :key="'en-desc1' + en.componentKey" />
      </v-sheet>

      <div class="font-weight-bold text-h6 ml-4 mt-4">일본어</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear('jp')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="jp.options" :key="'jp-desc1' + jp.componentKey" />
      </v-sheet>
    </v-sheet>
    <v-sheet class="d-flex justify-center mt-5">
      <v-btn class="rounded-10 px-6 mr-4" color="grey" large dark depressed @click="$emit('close')">
        <v-icon small left>mdi-cancel</v-icon>
        취소하기
      </v-btn>
      <v-btn class="rounded-10 px-6" color="primary" large dark depressed @click="createSubmit()">
        <v-icon small left>mdi-database-plus-outline</v-icon>
        추가하기
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
import { DEFAULT_PACKAGE_CODE } from "@/constant"
import { mapState } from "vuex"

export default {
  inject: ["concepts"],
  components: {
    TipTapWriter,
  },
  data: () => ({
    yesOrNo: ["Y", "N"],
    create: {
      name: "",
      code: "",
      option1Price: 0,
      option2Price: 0,
      option3Price: 0,
      option4Price: 0,
      option5Price: 0,
      option6Price: 0,
      isEnable: true,
      startAtDate: null,
      endAtDate: null,
      openAtDate: null,
      closeAtDate: null,
      isTraveler: "N",
      conceptId: null,
    },
    startAtMenu: false,
    endAtMenu: false,
    openAtMenu: false,
    closeAtMenu: false,

    conceptList: [
      {
        title: "선택안함",
        id: null,
      },
    ],
    conceptComponentKey: 0,

    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    componentKey: 0,

    en: {
      //TipTap
      options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      componentKey: 0,
    },
    jp: {
      //TipTap
      options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      componentKey: 0,
    },
    visibleCouponSelect: false,
  }),

  mounted() {
    this.conceptList.push(...this.concepts)
    this.conceptComponentKey++

    this.$http.get("/api/promotion/select").then((res) => {
      const _defaultPrice = res.data.find((e) => e.code == "default")
      const defaultPrice = _defaultPrice
      if (defaultPrice) {
        this.create.option1Price = defaultPrice.option1Price
        this.create.option2Price = defaultPrice.option2Price
        this.create.option3Price = defaultPrice.option3Price
        this.create.option4Price = defaultPrice.option4Price
        this.create.option5Price = defaultPrice.option5Price
        this.create.option6Price = defaultPrice.option6Price
      }
    })
  },

  methods: {
    clear(lang = "ko") {
      if (lang === "ko") {
        this.options.content = ""
        this.componentKey++
      } else if (lang === "en") {
        this.en.options.content = ""
        this.en.componentKey++
      } else if (lang === "jp") {
        this.jp.options.content = ""
        this.jp.componentKey++
      }
    },

    // 목록
    createSubmit: _.debounce(async function () {
      // 제목 검증
      // if (this.create.name == "") {
      //   alert("이름을 입력해주세요")
      //   return
      // }

      // 컨셉소개 내용 검증
      if (this.create.code == "") {
        alert("프로모션 코드를 입력해주세요")
        return
      }

      // 가격 검증
      if (Number(this.create.option1Price) < 0 || Number(this.create.option2Price) < 0 || Number(this.create.option3Price) < 0) {
        alert("유효한 가격 정보를 입력해주세요")
        return
      }

      if (!this.create.endAtDate) {
        alert("종료일자를 입력해주세요")
        return
      }
      console.log(this.create.startAtDate)
      console.log(this.create.endAtDate)

      this.$http
        .post("/api/promotion/insert", {
          params: {
            code: this.create.code,
            name: this.create.name,
            option1Price: Number(this.create.option1Price),
            option2Price: Number(this.create.option2Price),
            option3Price: Number(this.create.option3Price),
            option4Price: Number(this.create.option4Price),
            option5Price: Number(this.create.option5Price),
            option6Price: Number(this.create.option6Price),
            conceptId: this.create.conceptId,
            enabled: this.create.isEnable,
            openAt: this.create.openAtDate ? this.dayjs(this.create.openAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
            closeAt: this.create.closeAtDate ? this.dayjs(this.create.closeAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
            startAt: this.create.startAtDate ? this.dayjs(this.create.startAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
            endAt: this.create.endAtDate ? this.dayjs(this.create.endAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
            description: this.options.content ? this.options.content : null,
            description_en: this.en.options.content ? this.en.options.content : null,
            description_jp: this.jp.options.content ? this.jp.options.content : null,
          },
        })
        .then(async (res) => {
          if (res) {
            alert("정상적으로 등록되었습니다.")
            this.$emit("close")
          } else {
            alert("등록되지 않았습니다")
          }
        })
        .catch((e) => {
          console.log(e)
          alert(this.$t("alert.error_page"))
        })
    }, 500),
  },
}
</script>
<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
