<template>
  <v-sheet class="py-6 px-8">
    <p class="text-h6 font-weight-bold grey--text text--darken-3 text-center mb-6">이벤트 페이지 추가하기</p>

    <v-sheet class="mt-2 px-4">
      <v-text-field class="rounded-10" label="이름" dense outlined v-model.trim="create.name"></v-text-field>
      <v-text-field class="rounded-10" label="코드" dense outlined v-model.trim="create.code"></v-text-field>
      <v-select
        outlined
        dense
        :items="promotionList"
        :item-text="(item) => item.name || item.code"
        item-value="code"
        v-model="create.promotionCode"
        label="프로모션"
        :key="'promotion' + promotionComponentKey"
      ></v-select>

      <div class="font-weight-bold text-h6 ml-4 mt-4">한글</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear()">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="options" :key="'desc1' + componentKey" />
      </v-sheet>

      <div class="font-weight-bold text-h6 ml-4 mt-4">영어</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear('en')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="en.options" :key="'en-desc1' + en.componentKey" />
      </v-sheet>

      <div class="font-weight-bold text-h6 ml-4 mt-4">일본어</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear('jp')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="jp.options" :key="'jp-desc1' + jp.componentKey" />
      </v-sheet>
    </v-sheet>
    <v-sheet class="d-flex justify-center mt-5">
      <v-btn class="rounded-10 px-6 mr-4" color="grey" large dark depressed @click="$emit('close')">
        <v-icon small left>mdi-cancel</v-icon>
        취소하기
      </v-btn>
      <v-btn class="rounded-10 px-6" color="primary" large dark depressed @click="createSubmit()">
        <v-icon small left>mdi-database-plus-outline</v-icon>
        추가하기
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
import { mapState } from "vuex"

export default {
  inject: ["promotions"],
  components: {
    TipTapWriter,
  },
  data: () => ({
    create: {
      name: "",
      code: "",
      promotionCode: null,
    },

    promotionList: [
      {
        name: "선택안함",
        code: null,
      },
    ],
    promotionComponentKey: 0,

    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    componentKey: 0,

    en: {
      //TipTap
      options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      componentKey: 0,
    },
    jp: {
      //TipTap
      options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      componentKey: 0,
    },
  }),

  mounted() {
    this.promotionList.push(...this.promotions)
    this.promotionComponentKey++
  },

  methods: {
    clear(lang = "ko") {
      if (lang === "ko") {
        this.options.content = ""
        this.componentKey++
      } else if (lang === "en") {
        this.en.options.content = ""
        this.en.componentKey++
      } else if (lang === "jp") {
        this.jp.options.content = ""
        this.jp.componentKey++
      }
    },

    // 목록
    createSubmit: _.debounce(async function () {
      // 제목 검증
      // if (this.create.name == "") {
      //   alert("이름을 입력해주세요")
      //   return
      // }

      // 컨셉소개 내용 검증
      if (this.create.code == "") {
        alert("프로모션 코드를 입력해주세요")
        return
      }

      this.$http
        .post("/api/event/insert", {
          params: {
            code: this.create.code,
            name: this.create.name,
            promotionCode: this.create.promotionCode,
            description: this.options.content ? this.options.content : null,
            description_en: this.en.options.content ? this.en.options.content : null,
            description_jp: this.jp.options.content ? this.jp.options.content : null,
          },
        })
        .then(async (res) => {
          if (res) {
            alert("정상적으로 등록되었습니다.")
            this.$emit("close")
          } else {
            alert("등록되지 않았습니다")
          }
        })
        .catch((e) => {
          console.log(e)
          alert(this.$t("alert.error_page"))
        })
    }, 500),
  },
}
</script>
<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
