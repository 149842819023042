<template>
  <v-dialog max-width="440" :value="visible" content-class="rounded-10" style="z-index: 15" @input="$emit('input', $event)">
    <v-sheet class="rounded-10 px-2 px-md-4 py-4 py-md-10 d-flex flex-column align-center" color="#f7f2f7">
      <img src="@/assets/warning.png" class="icon" />

      <p class="text-h5 grey--text text--darken-2 font-weight-bold mt-2">{{ $t("dialog.refund-title") }}</p>
      <p class="text-center px-1 text-body-1" v-html="$t('dialog.refund-warning-message')"></p>

      <v-card flat width="100%" class="d-flex flex-column align-center py-4" style="border: 2px solid #dadada">
        <v-card-text class="text-center pa-0" style="color: #545454; font-size: 16px">{{ $t("dialog.refund-warning-list-1") }}</v-card-text>
        <v-card-text class="text-center pa-0 mt-2" style="color: #545454; font-size: 16px">{{ $t("dialog.refund-warning-list-2") }}</v-card-text>
        <v-card-text class="text-center pa-0 mt-2" style="color: #545454; font-size: 16px">{{ $t("dialog.refund-warning-list-3") }}</v-card-text>
        <v-card-text class="text-center pa-0 mt-2" style="color: #545454; font-size: 16px">{{ $t("dialog.refund-warning-list-4") }}</v-card-text>
      </v-card>
      <v-container class="d-flex flex-start" fluid>
        <ul class="text-body-2 text-md-body-1 grey--text text--darken-2">
          <li>{{ $t("dialog.refund-warning-list-5") }}</li>
          <li>{{ $t("dialog.refund-warning-list-6") }}</li>
        </ul>
      </v-container>
      <v-container class="d-flex justify-center">
        <span class="grey--text text--darken-2">{{ $t("dialog.refund-agree-check-label") }}</span
        ><v-simple-checkbox v-model="isCheck" color="primary" />
      </v-container>

      <v-container fluid class="d-flex justify-center mt-6">
        <v-btn color="white" class="dialog_btn rounded-2 mr-2 grey--text" width="35%" style="font-size: 20px" depressed @click="cancelClick" large> {{ $t("button.back") }} </v-btn>

        <v-btn color="#D8207E" class="dialog_btn rounded-2 ml-2" style="font-size: 20px" width="35%" depressed @click="confirmClick" large :disabled="!isCheck"> {{ $t("button.ok") }} </v-btn>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isCheck: false,
  }),
  methods: {
    confirmClick() {
      this.$emit("agree")
    },
    cancelClick() {
      this.$emit("cancel")
    },
  },
}
</script>
<style scoped>
.icon {
  width: 60px;
  height: 60px;
}
.dialog_btn {
  border: 2px solid #dadada !important;
  font-size: 16px;
}
::v-deep .v-btn:not(.v-btn--disabled) {
  color: white;
}
</style>
