<template>
  <div class="pb-16">
    <p class="px-4 mb-0 mt-4">
      <span class="font-danjunghae accent--text font-weight-medium" style="font-size: 30px" :lang="lang">
        {{ title }}
      </span>

      <span class="ml-2 text-body-1 text-md-h6" style="color: rgb(141, 141, 141); font-weight: 300 !important">
        {{ dayjs(new Date(created)).format("YYYY.M-D / HH:mm") }}
      </span>
    </p>

    <v-divider class="mx-4"></v-divider>

    <v-sheet class="py-2 px-4" style="margin-bottom: 180px">
      <v-row no-gutters>
        <v-col v-for="item in list" :key="item.imageUrl" cols="4" style="border: 1px solid white; cursor: pointer">
          <v-img width="100%" :style="selected_list.includes(item) ? 'filter: opacity(0.8);' : ''" :src="item.thumbanilUrl" @click="openDetail(item)">
            <div v-if="!item.requested">
              <v-btn
                v-if="selected_list.includes(item)"
                style="height: 24px; width: 24px; position: absolute; right: 8px; bottom: 8px"
                class="pa-0"
                fab
                x-small
                :disabled="item.requested"
                @click.stop="selectItem(item)"
              >
                <v-icon size="24" color="success">mdi-check-circle</v-icon>
              </v-btn>
              <v-btn
                v-if="!selected_list.includes(item)"
                style="height: 24px; width: 24px; position: absolute; right: 8px; bottom: 8px"
                class="pa-0"
                fab
                x-small
                :disabled="item.requested"
                @click.stop="selectItem(item)"
              >
                <v-icon size="24" color="#aaa">mdi-check-circle</v-icon>
              </v-btn>
            </div>
            <div v-if="item.requested" class="d-flex justify-center align-end px-1 py-1 sela-bottom-caption">{{ $t("page.picSelect.request-complete") }}</div>
          </v-img>
        </v-col>
      </v-row>
    </v-sheet>

    <div style="position: fixed; bottom: 0px; padding-bottom: 20px; width: 100%; max-width: 580px; background: white">
      <v-divider class="mt-2 mb-3 mx-4"></v-divider>

      <p class="px-4 mb-1 mt-2 text-center">
        <span class="text-body-2 text-md-body-2" style="color: rgb(83, 83, 83)"> {{ $t("page.picSelect.bottom-guide-text") }} </span>
      </p>

      <div class="d-flex justify-center">
        <p class="text-center mt-2" style="line-height: 16px; color: rgb(83, 83, 83)">
          <span
            >{{ $t("page.picSelect.curr-pic-count-prefix") }}<strong style="font-size: 24px; margin-left: 4px">{{ selected_list.length }}</strong>
            {{ $t("page.picSelect.curr-pic-count-suffix") }}</span
          ><br />
          <span class="grey--text text--darken-1" style="font-size: 12px"> {{ $t("page.picSelect.bottom-count-text-guide") }} </span>
        </p>

        <p>
          <span class="primary--text font-danjunghae ml-6" style="font-size: 34px">
            {{ $toComma(computed_total_price) }}
          </span>
          <span class="text-body-1" style="color: rgb(83, 83, 83) !important"> {{ $t("common.krw") }} </span>
        </p>
      </div>

      <div class="d-flex justify-center mb-6">
        <v-btn width="34%" large text color="rgb(84, 84, 84)" style="border: 2px solid #e9e9e9; font-size: 22px" class="rounded-md font-weight-medium" to="/mypage/home">
          {{ $t("button.go-back") }}
        </v-btn>

        <v-btn
          width="34%"
          large
          text
          color="grey"
          style="border: 2px solid #e9e9e9; background-color: #d9bddd; color: #66546d !important; font-size: 22px"
          class="ml-3 rounded-md font-weight-medium"
          @click="submit()"
          :disabled="selected_list.length == 0"
          :loading="pay_loading"
        >
          {{ $t("button.select-complete") }}
        </v-btn>
      </div>
    </div>

    <!-- 확대 -->
    <v-dialog v-model="dialog.detail" activator="parent" content-class="sela-dialog">
      <div class="sela-dialog-card" style="height: 85% !important">
        <DialogHeader @close="dialog.detail = false" />
        <v-img class="sela-dialog-card-content" :src="selected_item.imageUrl" :lazy-src="selected_item.thumbanilUrl" :key="selected_item.imageUrl" contain>
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular color="white" indeterminate></v-progress-circular>
            </div>
          </template>
        </v-img>

        <div class="px-2 mt-2">
          <PanelButton @click="dialog.filter = true" :title="$t('dialog.edit-bg')" :message="$t('dialog.edit-bg-guide')" />
          <ShotFilterDialog v-if="dialog.filter" :item="selected_item" :visible="dialog.filter" @close="dialog.filter = false" />
        </div>

        <div class="sela-dialog-card-footer relative">
          <v-btn outlined class="sela deactivate largeText" @click="share(selected_item)" width="49%">{{ $t("button.ask-friend") }}</v-btn>
          <v-btn
            v-if="!selected_list.includes(selected_item)"
            :disabled="selected_item?.requested"
            outlined
            class="sela largeText"
            @click="
              selectItem(selected_item)
              dialog.detail = false
            "
            width="49%"
          >
            {{ $t("button.select") }}
          </v-btn>
          <v-btn v-if="selected_list.includes(selected_item)" :disabled="selected_item?.requested" outlined class="sela largeText" @click="selectItem(selected_item)" width="28%">{{
            $t("button.deselect")
          }}</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex"
import DialogHeader from "@/views/components/DialogHeader"
import PanelButton from "@/views/components/PanelButton"
import ShotFilterDialog from "./ShotFilterDialog.vue"
import payletterService from "@/api/payletter-service"

export default {
  components: {
    DialogHeader,
    PanelButton,
    ShotFilterDialog,
  },

  data: () => ({
    title: "",
    qrcode: "",
    created: null,
    list: [],

    selected_item: "",

    selected_list: [],

    dialog: {
      detail: false,
      alert: false,
      filter: false,
    },

    // 토스페이먼츠
    tossPayments: null,

    imageHost: process.env.NODE_ENV == "development" ? "https://sela-castle.com" : "",

    pay_loading: false,

    //payletter window
    payletterWindow: null,
    payletterWindowEventListener: null,
  }),

  computed: {
    ...mapState(["lang", "lafia_user", "isSupportAbroadPay"]),
    ...mapGetters(["isKRW"]),

    // 최종 가격 계산
    computed_total_price() {
      const requestedCount = this.list.filter((x) => x.requested)
      const freeCount = requestedCount == 0 ? 1 : 0
      return Math.max((this.selected_list.length - freeCount) * 3900, 0)
    },
    // 주문 갯수
    orderCount() {
      const requestedCount = this.list.filter((x) => x.requested)
      const freeCount = requestedCount == 0 ? 1 : 0
      return Math.max(this.selected_list.length - freeCount, 0)
    },
  },

  mounted() {
    this.load()

    // 토스 기본설정
    this.tossInit()
  },

  methods: {
    // 토스 기본설정
    tossInit() {
      let recaptchaScript = document.createElement("script")
      recaptchaScript.setAttribute("src", "https://js.tosspayments.com/v1")
      document.head.appendChild(recaptchaScript)
    },

    load() {
      this.$http
        .post("/api/qrcode/select/byId", {
          params: {
            id: this.$route.query.id,
          },
        })
        .then((res) => {
          this.title = this.getTitle(res.data[0])

          this.qrcode = res.data[0].qrcode
          this.created = res.data[0].created
          const selected_filenames = res.data[0].selected_filenames.split(",")
          this.list = res.data[0].filenames.split(",").map((x) => {
            return {
              title: res.data[0].title,
              filename: x,
              thumbanilUrl: this.$imageUrl("/upload/auto_photo/resized/" + x),
              imageUrl: this.$imageUrl("/upload/auto_photo/selected/" + x),
              requested: selected_filenames.indexOf(x) > -1,
            }
          })
        })
    },

    openDetail(item) {
      this.selected_item = item
      this.dialog.detail = true
    },

    download(item) {
      const a = document.createElement("a")
      a.href = item.imageUrl
      a.download = item.filename
      a.click()
      //window.open(item.imageUrl, "_blank")
    },

    async share(item) {
      await this.$shareLink(item.imageUrl)
    },

    selectItem(item) {
      if (item.requested) {
        return
      }
      if (this.selected_list.includes(item)) {
        this.selected_list = this.selected_list.filter((x) => x !== item)
      } else {
        this.selected_list.push(item)
      }
    },

    submit: _.debounce(async function () {
      let orderId = Math.floor(Math.random() * 1000000000)

      try {
        this.pay_loading = true

        const orderRes = await this.$http.post("/api/order/v2/order", {
          orderId: orderId.toString(),
          userId: this.$store.state.lafia_user.user_id,
          orderDetailList: [
            {
              productName: "온라인추가보정신청",
              orderNum: this.orderCount,
              price: 3900,
              totalPrice: this.computed_total_price,
              finalPrice: this.computed_total_price,
            },
          ],
          memo: "무인촬영기",
          type: "온라인추가보정신청",
        })

        // 주문 입력하기
        // const orderRes = await this.$http.post("/api/order/insert", {
        //   params: {
        //     toss_id: orderId,
        //     user_id: this.$store.state.lafia_user.user_id,
        //     pay_amount: this.computed_total_price,
        //     pay_status: "결제미완료",
        //     status: "접수완료",
        //     memo: "무인촬영기",
        //   },
        // })

        // 선택한 사진 입력하기
        await this.$http.post("/api/qrcode/update/selected_filenames", {
          params: {
            id: this.$route.query.id,
            order_id: orderRes.data.id,
            selected_filenames: this.selected_list.map((x) => x.filename).toString(),
          },
        })

        if (this.computed_total_price > 0) {
          // 모든 작업이 완료된 후 토스 결제
          if (process.env.VUE_APP_TOSS_TEST_PAYMENT === "true" && window.confirm("테스트 결제 하기")) {
            this.$router.replace("/toss/success?orderId=" + orderId + "&paymentKey=test&amount=" + this.computed_total_price)
          } else {
            let orderName = this.title + " " + this.selected_list.length + "개 사진 선택"
            if (this.isSupportAbroadPay && !this.isKRW) {
              const { online_url, mobile_url } = await payletterService.requestInternationalPay({
                storeorderno: orderId.toString(),
                amount: this.computed_total_price,
                currency: "KRW",
                payinfo: orderName,
                payeremail: this.lafia_user.email,
                payerid: this.lafia_user.user_id,
              })

              var popOption = "width=430, height=555, resizable=no, scrollbars=no, status=no;"
              var popGlOption = "width=800, height=600, resizable=no, scrollbars=no, status=no;"
              if (this.$vuetify.breakpoint.mobile) {
                this.payletterWindow = window.open(mobile_url, "", popOption)
              } else {
                this.payletterWindow = window.open(online_url, "", popGlOption)
              }

              if (!this.payletterWindow || this.payletterWindow.closed || typeof this.payletterWindow.closed == "undefined") {
                alert(this.$t("alert.error_popup_blocked"))
                return
              }

              if (this.payletterWindow) {
                this.payletterWindowEventListener = async (event) => {
                  if (event.data.message === "pay-completed") {
                    const payloadData = event.data.data
                    this.payletterEventUnregister()
                    const { retCode, orderNo } = payloadData
                    if (retCode == "0") {
                      // 성공
                      this.$router.push("/payletter/success?orderId=" + orderId + "&amount=" + this.computed_total_price)
                    } else {
                      // 실패
                      this.$router.push("/fail")
                    }
                  }
                }
                window.addEventListener("message", this.payletterWindowEventListener)
              }
            } else {
              var tossPayments = TossPayments(process.env.VUE_APP_TOSS_PAYMENT_CLIENT_KEY)
              tossPayments
                .requestPayment("카드", {
                  amount: this.computed_total_price,
                  orderId: orderId,
                  orderName,
                  customerName: this.lafia_user.name,
                  customerEmail: this.lafia_user.email,
                  successUrl: window.location.origin + "/toss/success",
                  failUrl: window.location.origin + "/fail",
                  useEscrow: false,
                })
                .catch((error) => {
                  console.error("토스페이먼츠 결제", error)
                })
            }
          }
        } else {
          const picSelectRes = await this.$http.post("/api/qrcode/update/status/andSelected", {
            params: {
              id: this.$route.query.id,
              status: "선택완료",
              qrcode: this.qrcode,
              paymentKey: process.env.VUE_APP_TOSS_TEST_PAYMENT === "true" ? "test" : "free",
            },
          })
          console.log(picSelectRes)

          const result = await this.$http.post("/api/order/update/status", {
            params: {
              id: orderRes.data.id,
              status: "결제완료",
            },
          })
          if (result.data.affectedRows) {
            alert(this.$t("alert.succes_to_compl_select_picture"))
            this.$router.push("/mypage/home?type=picture")
          } else {
            alert(this.$t("alert.error_to_failed_select_picture"))
          }
        }
      } catch (e) {
        console.log(e)
        alert(this.$t("alert.error_doing_pay_unknown_error_2"))
      } finally {
        this.pay_loading = false
      }
    }, 500),

    payletterEventUnregister() {
      console.log("payletterEventUnregister")
      if (this.payletterWindowEventListener) {
        window.removeEventListener("message", this.payletterWindowEventListener)
      }
      this.payletterWindowEventListener = null
      this.payletterWindow = null
    },

    getTitle(item) {
      switch (this.lang) {
        case "en":
          return item.title_en || item.title
        case "jp":
          return item.title_jp || item.title
        default:
          return item.title
      }
    },
  },
  beforeDestroy() {
    this.payletterEventUnregister()
  },
}
</script>
<style scoped>
::v-deep .v-divider {
  border-width: medium 0 0 0;
}
</style>
