<template>
  <div>
    <v-img width="120" height="56" contain class="mx-auto mt-2" src="@/assets/deco/line_castle.png"></v-img>
    <p class="font-danjunghae text-center" style="font-size: 30px; line-height: 34px" :style="[warning ? { color: '#92285B' } : { color: '#66546d' }]" :lang="lang">
      <slot></slot>
    </p>
  </div>
</template>
<script>
import { mapState } from "vuex"
export default {
  props: {
    warning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["lang"]),
  },
}
</script>
<style scoped></style>
