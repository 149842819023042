<template>
  <div>
    <div class="py-8">
      <v-sheet class="py-4 px-4 mx-auto rounded-lg text-center" width="80%" outlined>
        <!-- 로고 & 문구 -->
        <div class="d-flex mb-2 mx-auto">
          <v-img height="40" width="140" contain src="@/assets/logo/main.svg"></v-img>
        </div>
        <p class="mb-6 text-subtitle-1 font-weight-medium grey--text text--darken-2">{{ $t("page.login.find-id-title") }}</p>
        <v-sheet :width="$vuetify.breakpoint.mobile ? '94%' : 280" class="mx-auto">
          <!-- 입력 -->
          <v-text-field
            outlined
            flat
            dense
            v-model.trim="name"
            :placeholder="$t('page.mypage.info.name')"
            persistent-placeholder
            @keyup.enter="submit()"
            @change="$v.name.$touch()"
            @blur="$v.name.$touch()"
            :error-messages="name_Errors"
          >
          </v-text-field>
          <!-- 입력 -->
          <v-text-field
            outlined
            flat
            dense
            v-model.trim="phone"
            type="number"
            hide-spin-buttons
            persistent-placeholder
            :placeholder="$t('page.mypage.info.phone')"
            @change="$v.phone.$touch()"
            @blur="$v.phone.$touch()"
            :error-messages="phone_Errors"
            @keyup.enter="submit()"
          >
          </v-text-field>

          <p class="mt-2 mb-8 text-caption text-center" v-html="$t('page.findId.guide-text')"></p>

          <!-- 발송 -->
          <v-btn width="100%" class="font-weight-bold" color="primary" dark large depressed @click="submit()"> {{ $t("page.login.find-id-title") }} </v-btn>

          <!-- 버튼 -->
          <div class="d-flex justify-center mt-4">
            <v-btn class="mr-1" color="grey darken-1" text small to="/auth/login">{{ $t("page.findId.back-to-login-button-title") }}</v-btn>
            <v-btn color="grey darken-1" text small to="/auth/find/password"> {{ $t("page.login.reset-password") }} </v-btn>
          </div>
        </v-sheet>

        <v-dialog max-width="440" v-model="show_warn_dialog" content-class="rounded-10" style="z-index: 999">
          <v-sheet class="rounded-10 pa-4">
            <p class="text-center mb-0">
              <v-icon size="80" color="#f2b8bf" class="">mdi-alert-circle-outline</v-icon>
            </p>

            <p class="text-center text-body mt-3 px-1" style="letter-spacing: 0px !important">
              {{ warn_error_message }}
            </p>

            <div class="d-flex justify-center mt-6">
              <v-btn color="#f2b8bf" class="rounded-10" dark width="48%" depressed @click="warn_error_message = ''"> {{ $t("button.ok") }} </v-btn>
            </div>
          </v-sheet>
        </v-dialog>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate"
import { required, sameAs, minLength, email } from "vuelidate/lib/validators"
import authService from "@/api/auth-service"

export default {
  mixins: [validationMixin],
  validations: {
    name: {
      required,
    },
    phone: {
      required,
    },
  },
  data: () => ({
    warn_error_message: "",

    name: "",
    phone: "",
  }),
  computed: {
    show_warn_dialog: {
      get() {
        return !!this.warn_error_message
      },
      set(value) {
        if (!value) {
          this.warn_error_message = ""
        }
      },
    },

    // 실명 Error 문구
    name_Errors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push(this.$t("page.findId.warning-input-name"))
      return errors
    },
    // 연락처 Error 문구
    phone_Errors() {
      const errors = []
      if (!this.$v.phone.$dirty) return errors
      !this.$v.phone.required && errors.push(this.$t("page.findId.warning-input-phone"))
      return errors
    },
  },
  methods: {
    async submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const result = await authService.findId(this.name, this.phone)
          if (result.error) {
            this.warn_error_message = result.error.message
          } else {
            const user_id = result.user_id
            this.$router.push("/auth/find/success?userId=" + user_id)
          }
        } catch (e) {
          alert(this.$t("alert.error_page"))
        }
      }
    },
  },
}
</script>
<style></style>
