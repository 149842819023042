<template>
  <div>
    <v-img src="@/assets/deco/card-top-deco.png" max-width="240px" class="mx-auto" />
    <p class="accent--text text-center mb-2 font-danjunghae" style="font-size: 30px" :lang="lang">{{ $t("page.reservation.date-select-title") }}</p>

    <div class="dateSelectBox">
      <!-- 날짜 선택 -->
      <DateSelector
        :color="$vuetify.theme.currentTheme.primary"
        :width="330"
        class="mt-7 mb-4 mx-auto"
        @dateUpdated="dateUpdated_handler"
        :dateInit="date"
        :key="'DateSelector' + dateSelectComponentKey"
      />
      <!-- 시간 선택 -->
      <div v-if="!loading" class="d-flex justify-center mt-5 flex-wrap mx-auto" style="width: 350px" :key="componentKey + 'time'">
        <v-btn
          v-for="item in time_option_list"
          :key="item"
          height="36"
          depressed
          class="timeSelectBtn rounded-10 px-2 mb-1 my-1"
          :class="{ enable: selected_time == item }"
          :disabled="already_reserved_time_list.includes(item)"
          @click="!already_reserved_time_list.includes(item) && selected_time != item ? (selected_time = item) : (selected_time = '')"
        >
          {{ item }}
        </v-btn>
      </div>
      <!-- 로딩 (시간 선택) -->
      <v-progress-circular v-if="loading" indeterminate color="primary" size="40" style="height: 72px" class="d-flex mx-auto mt-8"></v-progress-circular>

      <!-- warning guide -->
      <div class="text-center mt-4">
        <div class="warning--text" style="font-size: 12px" v-html="$t('page.reservation.do-reserve-guide-1')"></div>
        <div class="warning--text" style="font-size: 12px" v-html="$t('page.reservation.do-reserve-guide-2')"></div>
      </div>
    </div>

    <!-- 가능한 인원 표시하기 -->
    <p v-if="selected_time" class="text-body-2 text-center mt-4 grey--text text--darken-1">
      {{ $t("page.reservation.left-seats") }}
      <span class="ml-2 text-darken-2" style="font-size: 20px; font-weight: 600">{{ getMaxAvailableRoom - selected_time_already_reserved }}</span>
      {{ $t("page.reservation.left-seats-unit") }}
    </p>
    <div v-else style="height: 28px"></div>

    <!-- 대여/미대여 인원 입력 -->
    <div class="mx-8 mt-6" style="min-height: 450px">
      <div class="d-flex align-center justify-space-between text-body-2 font-weight-medium px-lg-10">
        <div class="d-flex flex-column">
          <span class="text-h6">{{ $t("page.reservation.default-package-label") }}</span>
          <div class="text-body-1">
            <span class="primary--text font-weight-bold">{{ $toComma(getClothPrice) }}</span
            >&nbsp;원
          </div>
        </div>
        <div class="d-flex align-center">
          {{ $t("page.reservation.people-number") }}
          <v-select
            style="width: 72px; margin: 0 6px; z-index: 5"
            class="rounded-10 shrink my-0"
            hide-details
            outlined
            :disabled="selected_time_already_reserved == getMaxAvailableRoom"
            dense
            append-icon="mdi-chevron-down"
            :items="select_list.number"
            v-model="cloth"
            @change="checkTotalPerson()"
            :key="componentKey + 'cloth'"
          >
            <template v-slot:selection="{ item }">
              <span class="pl-1 pb-2px">{{ item }}</span>
            </template>
            <template v-slot:append>
              <v-icon style="width: 12px">mdi-chevron-down</v-icon>
            </template>
          </v-select>
          {{ $t("page.reservation.people-unit") }}
        </div>
      </div>
    </div>
    <!-- 대여 & 미대여 입력 end -->

    <!-- 예약하기 하단 -->
    <v-sheet style="width: 100%; max-width: 580px; position: fixed; bottom: 0px; padding-bottom: 20px; border-top: 1px solid #e0e0e0; z-index: 10">
      <!-- 최종가격 -->
      <p class="text-end font-danjunghae ml-1 px-6 mt-2 mb-0" style="font-size: 40px; color: #5b4a62">
        <span v-if="selected_coupon" class="mr-2 text-body-2 warning--text font-weight-light">{{ $t("page.pay.coupon-apply-label", { amount: getDiscountPriceStr }) }}</span>
        <!-- <span v-if="!!promotionPriceInfo" class="mr-2 text-body-2 warning--text font-weight-light">(할인 적용가)</span> -->
        <span class="mr-2 text-body-1 grey--text text--darken-2" style="vertical-align: 10%">{{ $t("page.reservation.total-reserv-persons", { person: this.cloth }) }}</span>
        {{ $toComma(computed_total_price) }}<span class="text-body-1 ml-1 grey--text text--darken-2">{{ $t("common.krw") }}</span>
      </p>

      <!-- 쿠폰, 예약하기 -->
      <div class="d-flex px-6 pb-4">
        <v-btn
          large
          width="40%"
          color="#f1b2ba"
          style="font-size: 20px; color: #693838"
          class="flex-grow-1 rounded-10 pb-1"
          depressed
          outlined
          :disabled="selected_time == '' || !cloth"
          @click="showCouponSelectDialog()"
        >
          {{ $t("button.coupon") }}<span v-show="available_coupon_cnt">({{ available_coupon_cnt }})</span>
        </v-btn>
        <v-btn
          large
          width="40%"
          color="primary"
          style="font-size: 25px"
          class="flex-grow-1 rounded-10 pb-1 ml-4 darken-1"
          depressed
          :disabled="selected_time == '' || !cloth"
          @click="onReservationWithApplicableCouponCheck"
          :loading="pay_loading"
        >
          {{ $t("button.pay") }}
        </v-btn>
      </div>
    </v-sheet>

    <MyCouponSelectList
      type="이용권"
      :visible="show_coupon_select"
      @close="show_coupon_select = false"
      @input="(e) => (show_coupon_select = e)"
      :key="'coupon' + show_coupon_select_componentKey"
      @apply="(item) => applyCoupon(item)"
      :showNoCoupon="showNoCoupon"
    />

    <TwoButtonDialogVue
      :msg="twobutton_dialog_messsage"
      @input="twoButtonDialogInputEvent"
      positiveText="button.pay"
      negativeText="button.coupon-select"
      @positive="positiveDialogEvent"
      @negative="negativeDialogEvent"
      :visible="show_twobutton_dialog"
      :key="'twobutton' + show_twobutton_dialog_componentKey"
    />

    <NormalWarningDialog :msg="warning_dialog_messsage" @input="warningDialogInputEvent" @confirm="confirmDialog" :visible="show_warning_dialog" :key="'normal' + show_warning_dialog_componentKey" />
  </div>
</template>
<script>
import { mapState } from "vuex"
import DateSelector from "@/components/DateSelector"
import { Holidays } from "@/constant"
import { mapGetters, mapActions } from "vuex"
import MyCouponSelectList from "@/views/components/MyCouponSelectList"
import NormalWarningDialog from "@/views/components/NormalWarningDialog"
import TwoButtonDialogVue from "@/views/components/TwoButtonDialog"
import { DEFAULT_PACKAGE_CODE } from "@/constant"
import payletterService from "@/api/payletter-service"

export default {
  components: {
    DateSelector,
    MyCouponSelectList,
    NormalWarningDialog,
    TwoButtonDialogVue,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    base: {
      type: Object,
      required: true,
    },
    promotion: {
      type: Object | null | undefined,
      required: true,
    },
  },

  data: () => ({
    select_list: {
      number: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },

    date: null,
    dateSelectComponentKey: 0,

    time_option_list: ["12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"],
    selected_time: "",

    already_reserved_time_list: [],
    already_reserved_person_list: [],

    cloth: 1,
    componentKey: 0,

    loading: true,

    tossPayments: null,
    reservation_dogin: false,

    show_warning_dialog: false,
    warning_dialog_messsage: "",
    show_warning_dialog_componentKey: 0,

    show_coupon_select_componentKey: 0,
    show_coupon_select: false,

    selected_coupon: null,
    available_coupon_cnt: 0,

    show_twobutton_dialog: false,
    twobutton_dialog_messsage: "",
    show_twobutton_dialog_componentKey: 0,
    showNoCoupon: true,

    //결제 진행중
    pay_loading: false,

    //payletter window
    payletterWindow: null,
    payletterWindowEventListener: null,
  }),

  created() {
    console.log(this.base)
    // 상품 선택 필수
    if (!this.name || !this.base) {
      alert(this.$t("alert.error_wrong_access"))

      // let path = "/reservation/package"
      // if (this.base.code !== "default-package") {
      //   const delim = path.includes("?") ? "&" : "?"
      //   path = path + `${delim}productId=${this.base.code}`
      // }

      // if (this.promotion.code) {
      //   const delim = path.includes("?") ? "&" : "?"
      //   path = path + `${delim}packageEvent=${this.promotion.code}`
      // }

      this.$router.replace("/reservation/package")
    }
  },

  computed: {
    ...mapState(["lang", "lafia_user", "isSupportAbroadPay"]),
    ...mapGetters(["isKRW"]),

    getMaxAvailableRoom() {
      return 15
    },

    getClothPrice() {
      if (this.isWeekendOrHoliday) {
        return this.base.clothPriceWeekend
      } else {
        return this.base.clothPrice
      }
    },

    getDiscountPrice() {
      if (!this.selected_coupon) {
        return 0
      }
      const price = this.selected_coupon.discount_KRW
      return price || 0
    },
    getDiscountPriceStr() {
      const price = this.getDiscountPrice
      return `${this.$toComma(Math.min(price || 0, this.computed_total_price_original))}${this.$t("common.krw")}`
    },

    // 주말 또는 공휴일인지 확인
    isWeekendOrHoliday() {
      if (this.date) {
        return this.date.day() === 6 || this.date.day() === 0 || Holidays.includes(this.date.format("YYYY-MM-DD"))
      } else {
        return false
      }
    },

    computed_total_price_original() {
      return this.getClothPrice * this.cloth
    },

    // 최종 가격 계산
    computed_total_price() {
      return Math.max(this.getClothPrice * this.cloth - this.getDiscountPrice, 0)
    },

    // 선택한 타임의 기예약인원
    selected_time_already_reserved() {
      return this.already_reserved_person_list.find((item) => item.time === this.selected_time)?.person || 0
    },
  },

  watch: {
    selected_time(newTime, oldTime) {
      // 해당 타임에 기예약인원 확인
      let already_reserved = this.already_reserved_person_list.find((item) => item.time === newTime)?.person || 0

      // 기예약인원 포함 15명 이상일시
      if (this.cloth + already_reserved > this.getMaxAvailableRoom) {
        alert(newTime + " 타임은 현재 " + (this.getMaxAvailableRoom - already_reserved) + "명까지 예약하실 수 있습니다")
        this.selected_time = ""
        this.cloth = 1
        this.componentKey++
      }
    },

    // 날짜가 변경이 된 경우
    date: {
      handler(newVal, oldVal) {
        // 날짜 변경시 promotion 정보 업데이트 - 날짜 유효성 여부 체크
        console.log("date handler", newVal.format("MM/DD"), oldVal && oldVal.format("MM/DD"))
        if (newVal && !oldVal) {
          this.checkPromotionDate(this.promotion || this.base, true)
        } else if (newVal && oldVal && !newVal.isSame(oldVal, "day")) {
          this.checkPromotionDate(this.promotion || this.base)
        }
      },
      immediate: false,
    },
  },

  async mounted() {
    console.log("Castle vue mounted!!")
    // 토스 기본설정
    await this.tossInit()

    if (this.lafia_user.is_logined && this.lafia_user.user_id) {
      this.$http
        .get("/api/coupon/getUserCoupons", {
          params: {
            user_id: this.lafia_user.user_id,
            type: "이용권",
          },
        })
        .then((res) => {
          this.available_coupon_cnt = res.data.length
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },

  methods: {
    // 인원 확인
    checkTotalPerson() {
      // 인원 15명 이상 입력 시
      if (this.cloth > this.getMaxAvailableRoom) {
        // 선택한 시간 초기화
        this.selected_time = ""
        this.cloth = 1

        alert(this.$t("alert.warning_reservation_max_exceed"))
        this.componentKey++

        // 인원 15명 이하 입력시
      } else {
        // 해당 타임에 기예약인원 확인
        let already_reserved = this.already_reserved_person_list.find((item) => item.time === this.selected_time)?.person || 0

        // 기예약인원 포함 15명 이상일시
        if (this.cloth + already_reserved > this.getMaxAvailableRoom) {
          // 선택한 시간 초기화
          this.selected_time = ""
          this.cloth = 1
          alert(this.$t("alert.warning_reservation_max_exceed_with_param", { time: this.selected_time, person: this.getMaxAvailableRoom - already_reserved }))
          this.componentKey++
        }
      }
    },

    // 토스 기본설정
    tossInit() {
      let recaptchaScript = document.createElement("script")
      recaptchaScript.setAttribute("src", "https://js.tosspayments.com/v1")
      document.head.appendChild(recaptchaScript)
    },

    // DateSelector에서 date 가져오기
    dateUpdated_handler(date) {
      this.date = date.startOf("day")
      this.loading = true

      //날짜가 바뀐 경우 정보 초기화
      this.cloth = 1

      this.checkOpenTime()
    },

    // 선택된 날짜의 닫힌 시간 확인하기
    checkOpenTime() {
      // 선택한 시간 초기화
      this.selected_time = ""

      let defaultExpireTime = null

      if (this.base && this.base.endAt) {
        console.log(this.base.endAt)
        defaultExpireTime = this.dayjs(this.base.endAt)
      }

      // 2024-03-01 이전
      if (this.date.isBefore(this.dayjs("2024-03-01"), "date")) {
        // 전부 닫기
        this.already_reserved_time_list = this.time_option_list
        this.loading = false
      }

      // 기본 가격에 따른 endAt 날짜에 따라 open 여부 결정
      else if (defaultExpireTime && this.date.isAfter(defaultExpireTime, "date")) {
        // 전부 닫기
        this.already_reserved_time_list = this.time_option_list
        this.loading = false
      }

      // 지난 날짜의 경우
      else if (this.date.isBefore(this.dayjs(), "date")) {
        // 전부 닫기
        this.already_reserved_time_list = ["12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"]
        this.loading = false
      }
      // 월요일의 경우
      else if (this.date.day() == 1) {
        // 전부 닫기
        this.already_reserved_time_list = ["12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"]
        this.loading = false
      }
      // 월요일 외
      else {
        // 초기화
        this.already_reserved_time_list = []
        this.already_reserved_person_list = []

        // 오늘 날짜일시 예약시간이 지났는지 확인
        this.TodayTimePassCheck()

        // 15명인 시간 닫기 (각 시간별 예약인원 체크)
        this.$http
          .post("/api/reservation/select/specific/time/castle/check2", {
            params: {
              year: this.date.year(),
              month: this.date.month() + 1,
              day: this.date.date(),
            },
          })
          .then((res) => {
            return res.data.map((e) => ({
              time: e.time,
              person: Number(e.total_cloth),
            }))
          })
          .then((list) => {
            // 각 타임별 기예약인원 배열로 저장
            this.already_reserved_person_list.push(...list)

            for (let item of list) {
              if (item.person == this.getMaxAvailableRoom) {
                this.already_reserved_time_list.push(item.time)
              }
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    // 오늘 날짜일시 예약시간이 지났는지 확인
    TodayTimePassCheck() {
      // 오늘 날짜일 경우
      if (this.dayjs().isSame(this.date, "date")) {
        this.time_option_list.forEach((time) => {
          // 30 분 이전 까지만 예약 가능
          const now = this.dayjs().add(30, "minute")

          let timeToCheck = this.dayjs()
            .hour(parseInt(time.split(":")[0]))
            .minute(parseInt(time.split(":")[1]))

          // 현재 시각이 지났을 경우
          if (timeToCheck.isBefore(now)) {
            this.already_reserved_time_list.push(time)
          }
        })
      }
    },

    onReservationWithApplicableCouponCheck: _.debounce(async function () {
      if (this.available_coupon_cnt > 0 && !this.selected_coupon) {
        this.showTwoButtonDialog("dialog.confirm_applicable_coupons")
      } else {
        this.reservation()
      }
    }, 500),

    // 예약하기
    reservation: _.debounce(async function () {
      let orderId = Math.floor(Math.random() * 1000000000)
      let promotionCode = this.promotion ? this.promotion.code : null

      if (!this.lafia_user.is_logined) {
        if (promotionCode) {
          this.$store.commit("ADD_PROMOTION_CODE", promotionCode)
        }
        alert(this.$t("alert.error_need_authentication"))
        let loginPath = "/auth/login"
        if (this.package_product_info.code !== DEFAULT_PACKAGE_CODE) {
          loginPath += `?productId=${this.package_product_info.code}`
        }
        if (promotionCode) {
          const delim = loginPath.includes("?") ? "&" : "?"
          loginPath = loginPath + `${delim}packageEvent=${promotionCode}`
        }
        this.$router.push(loginPath)
        return
      }

      try {
        // 결제 진행중
        this.pay_loading = true
        // 오늘 날짜일시 예약시간이 지났는지 재확인
        await this.TodayTimePassCheck()

        // 시간 지났을 경우 종료
        if (this.already_reserved_time_list.includes(this.selected_time)) {
          this.pay_loading = false // 결제 진행 중 취소
          this.$router.go(0)
          return
        }

        // 결제 전 기예약인원 재확인
        const res = await this.$http.post("/api/reservation/select/specific/time/castle/check", {
          params: {
            year: this.date.year(),
            month: this.date.month() + 1,
            day: this.date.date(),
            time: this.selected_time,
          },
        })

        // 기예약인원 포함 15명 이상일 시 종료
        let already_reserved = 0

        if (res.data.length) {
          already_reserved = Number(res.data[0].total_cloth)
        }

        if (this.cloth + already_reserved > this.getMaxAvailableRoom) {
          alert(
            "예약 도중 다른 예약으로 인해 " + this.selected_time + " 현재 최대 " + (this.getMaxAvailableRoom - already_reserved) + "명까지 예약하실 수 있으므로 인원 또는 타임을 변경해주시기 바랍니다"
          )
          this.pay_loading = false // 결제 진행 중 취소
          return
        }

        let defaultClothPrice = this.isWeekendOrHoliday ? this.base.clothPriceWeekend : this.base.clothPrice
        let clothOrder = {
          productName: this.isWeekendOrHoliday ? "주말온라인이용권" : "온라인이용권",
          orderNum: this.cloth,
          price: defaultClothPrice,
          totalPrice: this.cloth * defaultClothPrice,
          finalPrice: Math.max(this.cloth * defaultClothPrice - this.getDiscountPrice, 0),
          couponId: this.selected_coupon && this.selected_coupon.issuedId,
        }

        clothOrder = {
          ...clothOrder,
          discountCode: promotionCode,
        }

        let orderList = []
        this.cloth && orderList.push(clothOrder)

        const orderRes = await this.$http.post("/api/order/v2/order", {
          orderId: orderId.toString(),
          userId: this.lafia_user.user_id,
          orderDetailList: orderList,
          memo: promotionCode || "",
          type: "온라인이용권",
        })

        let params = {
          order_id: orderRes.data.id,
          user_id: this.lafia_user.user_id,
          category: "라피아캐슬",
          maker: null,
          concept: null,
          year: this.date.year(),
          month: this.date.month() + 1,
          day: this.date.date(),
          time: this.selected_time,
          cloth: this.cloth,
          clothPrice: this.cloth * this.getClothPrice,
          uncloth: 0,
          unclothPrice: 0,
          promotionCode: promotionCode,
          packageCode: this.base.code,
        }

        // 시간예약 입력하기
        await this.$http.post("/api/reservation/v2/reserve", {
          ...params,
        })

        if (window.location.host.startsWith("localhost") && window.confirm("테스트 결제 하기")) {
          // 모든 작업이 완료된 후 토스 결제
          this.$router.replace("/toss/success?orderId=" + orderId + "&paymentKey=test&amount=" + this.computed_total_price)
        } else if (/*this.computed_total_price_original > 0 &&*/ this.computed_total_price === 0) {
          this.$router.replace("/toss/success?orderId=" + orderId + "&paymentKey=free&amount=" + this.computed_total_price)
        } else {
          let productTitle = this.base.code === DEFAULT_PACKAGE_CODE ? "이용권" : `${this.base.name} 이용권`
          let orderName = productTitle + (this.cloth ? " " + this.cloth + "명" : "")

          if (this.isSupportAbroadPay && !this.isKRW) {
            const { online_url, mobile_url } = await payletterService.requestInternationalPay({
              storeorderno: orderId.toString(),
              amount: this.computed_total_price,
              currency: "KRW",
              payinfo: orderName,
              payeremail: this.lafia_user.email,
              payerid: this.lafia_user.user_id,
            })

            var popOption = "width=430, height=555, resizable=no, scrollbars=no, status=no;"
            var popGlOption = "width=800, height=600, resizable=no, scrollbars=no, status=no;"
            if (this.$vuetify.breakpoint.mobile) {
              this.payletterWindow = window.open(mobile_url, "", popOption)
            } else {
              this.payletterWindow = window.open(online_url, "", popGlOption)
            }

            if (!this.payletterWindow || this.payletterWindow.closed || typeof this.payletterWindow.closed == "undefined") {
              alert(this.$t("alert.error_popup_blocked"))
              return
            }

            if (this.payletterWindow) {
              this.payletterWindowEventListener = (event) => {
                if (event.data.message === "pay-completed") {
                  const payloadData = event.data.data
                  this.payletterEventUnregister()
                  const { retCode, orderNo } = payloadData
                  if (retCode == "0") {
                    // 성공
                    this.$router.push("/payletter/success?orderId=" + orderId + "&amount=" + this.computed_total_price)
                  } else {
                    // 실패
                    this.$router.push("/fail")
                  }
                }
              }
              window.addEventListener("message", this.payletterWindowEventListener)
            }
          } else {
            var tossPayments = TossPayments(process.env.VUE_APP_TOSS_PAYMENT_CLIENT_KEY)
            tossPayments
              .requestPayment("카드", {
                amount: this.computed_total_price,
                orderId: orderId,
                orderName: orderName,
                customerName: this.lafia_user.name,
                customerEmail: this.lafia_user.email,
                successUrl: window.location.origin + "/toss/success",
                failUrl: window.location.origin + "/fail",
                useEscrow: false,
              })
              .catch((error) => {
                console.error("토스페이먼츠 결제", error)
                this.pay_loading = false // 결제 진행 중 취소
              })
          }
        }
      } catch (e) {
        console.log(e)
        alert(this.$t("alert.error_page"))
      } finally {
        this.pay_loading = false // 결제 진행 중 취소
      }
    }, 500),

    getDiscountRate(original, final) {
      return ((original - final) / original) * 100
    },

    // 해당 날짜가 예약 가능 날짜인지
    checkPromotionDate(product, isSkip = false) {
      console.log("checkPromotionDate", product)
      // 해당 패키지가 예약 가능 아닌지 체크가 필요함.
      const startAt = this.dayjs(product.startAt)
      const endAt = this.dayjs(product.endAt)

      if (!this.date.isBetween(startAt, endAt, "day", "[]")) {
        if (!isSkip) {
          // 해당 프로 모션의 예약 기간이 아닙니다.
          if (this.dayjs().isAfter(endAt, "day")) {
            this.showWarningDialog("dialog.promotion-warning-end-guide")
          } else {
            this.showWarningDialog(this.$t("dialog.promotion-warning-date-guide", { startAt: startAt.format("YYYY/MM/DD"), endAt: endAt.format("YYYY/MM/DD") }))
          }
        } else {
          if (this.dayjs().isBefore(startAt)) {
            this.date = startAt.startOf("day")
          } else {
            this.date = this.dayjs().startOf("day")
          }
          this.dateSelectComponentKey++
        }
      }
    },

    showCouponSelectDialog(showNoCoupon = true) {
      this.showNoCoupon = showNoCoupon
      this.show_coupon_select_componentKey++
      this.show_coupon_select = true
    },

    applyCoupon(coupon) {
      console.log("applyCoupon", coupon)
      this.selected_coupon = null

      if (!coupon) {
        return
      }
      const { discount_KRW, startAt, expireAt, type, isExpired } = coupon
      if (isExpired) {
        return
      } else if (!this.dayjs().isBetween(this.dayjs(startAt), this.dayjs(expireAt), "day", "[]")) {
        return
      } else if (type !== "이용권할인") {
        return
      }
      this.selected_coupon = coupon
    },

    showWarningDialog(message) {
      this.warning_dialog_messsage = message
      this.show_warning_dialog = true
      this.show_warning_dialog_componentKey++
    },

    confirmDialog() {
      this.show_warning_dialog = false
      if (this.warning_dialog_messsage === "dialog.promotion-warning-end-guide") {
        this.$router.replace("/reservation/package")
      } else {
        const product = this.promotion || this.base
        if (product) {
          const startAt = this.dayjs(product.startAt).startOf("day")
          if (this.dayjs().isBefore(startAt)) {
            this.date = startAt.startOf("day")
          } else {
            this.date = this.dayjs().startOf("day")
          }
          this.dateSelectComponentKey++
        }
      }
      this.warning_dialog_messsage = ""
      this.show_warning_dialog_componentKey++
    },

    warningDialogInputEvent(e) {
      !e && this.confirmDialog()
    },

    showTwoButtonDialog(message) {
      this.twobutton_dialog_messsage = message
      this.show_twobutton_dialog = true
      this.show_twobutton_dialog_componentKey++
    },

    closeTwoButtonDialog() {
      this.show_twobutton_dialog = false
      this.twobutton_dialog_messsage = ""
      this.show_twobutton_dialog_componentKey++
    },

    positiveDialogEvent() {
      this.closeTwoButtonDialog()
      this.reservation()
    },

    negativeDialogEvent() {
      this.closeTwoButtonDialog()
      this.showCouponSelectDialog(false)
    },

    twoButtonDialogInputEvent(e) {
      if (!e) {
        this.closeTwoButtonDialog()
      }
    },

    payletterEventUnregister() {
      console.log("payletterEventUnregister")
      if (this.payletterWindowEventListener) {
        window.removeEventListener("message", this.payletterWindowEventListener)
      }
      this.payletterWindowEventListener = null
      this.payletterWindow = null
    },
  },
  beforeDestroy() {
    this.payletterEventUnregister()
  },
}
</script>
<style scoped>
.timeSelectBtn {
  border: 1px solid lightgray;
  color: #515151;
  font-size: 16px;
  font-weight: normal;
  margin: 0 3px;
  background-color: white !important;
}

.enable {
  background: #8d75cb !important;
  color: white !important;
}

.dateSelectBox {
  border-radius: 10px;
  border: 2px solid #d6d6d6;
  margin: 10px;
  padding-bottom: 20px;
}
</style>
