<template>
  <v-sheet class="d-flex" height="100">
    <v-sheet v-for="(item, index) in menus" :key="item.key" @click="() => selectTab(index)" class="tabItem">
      <img :src="getMenuIcon(item.key, selected === index)" />
    </v-sheet>
  </v-sheet>
</template>
<script>
export default {
  props: ["selected"],
  data: () => ({
    menus: [
      // {
      //   key: "early",
      // },
      {
        key: "package",
      },
      {
        key: "makeup",
      },
    ],
  }),

  methods: {
    getMenuIcon(key, isActive) {
      switch (key) {
        case "early":
          return isActive ? require("@/assets/menus/early-active.png") : require("@/assets/menus/early.png")
        case "package":
          return isActive ? require("@/assets/menus/package-active.png") : require("@/assets/menus/package.png")
        case "makeup":
          return isActive ? require("@/assets/menus/en/makeup-active.png") : require("@/assets/menus/makeup_t.png")
        default:
          break
      }
      return isActive ? require("@/assets/menus/package-active.png") : require("@/assets/menus/package.png")
    },

    selectTab(index) {
      this.$emit("update:selected", index)
    },
  },
}
</script>
<style scoped>
.tabItem {
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  margin: auto;
}
.tabItem img {
  height: 60px;
}
</style>
