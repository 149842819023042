<template>
  <div>
    <!-- 페이지 제목 -->
    <div v-if="title.secure" style="position: absolute; left: calc(50% - 142px); top: 50px" class="mx-auto">
      <img src="@/assets/reservation/promotion-deco.png" width="300" />
    </div>
    <ReservationTitleLogo v-if="title.secure" :warning="true"> {{ title.name }} <br v-if="title.name" />{{ $t("page.reservation.secret-title") }} </ReservationTitleLogo>
    <TitleLogo v-else :title="$t('page.reservation.package-title')" :warning="false" />
    <v-divider></v-divider>
    <v-sheet v-if="!loading" style="padding-bottom: 80px; position: relative">
      <PackageStoreTabMenu :selected.sync="selectedTabIdx" />
      <!-- <v-sheet v-if="selectedTabIdx === 0">
        <EarlyStore />
      </v-sheet> -->
      <v-sheet v-if="selectedTabIdx === 0">
        <PackageStore />
      </v-sheet>
      <v-sheet v-else-if="selectedTabIdx === 1">
        <MakeupArtists />
      </v-sheet>
    </v-sheet>
    <v-sheet v-else class="text-center mt-14">
      <v-progress-circular indeterminate color="primary" size="48"></v-progress-circular>
    </v-sheet>
  </div>
</template>
<script>
import { computed } from "vue"
import { mapActions, mapMutations, mapState } from "vuex"
import TitleLogo from "@/views/components/TitleLogo"
import ReservationTitleLogo from "@/views/components/ReservationTitleLogo"
import PackageStoreTabMenu from "./components/PackageStoreTabMenu"
import PackageStore from "./components/PackageStore"
import MakeupArtists from "./components/MakeupArtists"
import EarlyStore from "./components/EarlyStore"

export default {
  components: {
    TitleLogo,
    ReservationTitleLogo,
    PackageStoreTabMenu,
    PackageStore,
    MakeupArtists,
    EarlyStore,
  },

  provide() {
    return {
      package_product_info: computed(() => this.package_product_info),
      promotion_info: computed(() => this.promotionPriceInfo),
    }
  },

  data: () => ({
    loading: true,
    promotionPriceInfo: null,
    selectedTabIdx: 0,
    package_product_info: null,
  }),

  created() {
    const type = this.$route.query.type
    switch (type) {
      // case "early":
      //   this.selectedTabIdx = 0
      //   break
      case "makeup":
        this.selectedTabIdx = 1
        break
      default:
        this.selectedTabIdx = 0
        break
    }
  },

  mounted() {
    this.getPromotionPrice({ packageProductId: this.getPackageProductId, promotionCode: this.getPromotionCode })
      .then((res) => {
        console.log("getPromotionPrice", res)

        this.package_product_info = res.packagePrice

        if (this.package_product_info) {
          const openAt = this.dayjs(this.package_product_info.openAt)
          const closeAt = this.dayjs(this.package_product_info.closeAt)
          if (openAt && closeAt) {
            if (!this.dayjs().isBetween(openAt, closeAt, "day", "[]")) {
              alert(this.$t("alert.error_invalid_product_page"))
              this.$router.replace("/")
              return
            }
          }
        } else {
          alert(this.$t("alert.error_invalid_product_page"))
          this.$router.replace("/")
          return
        }
        let _promotionInfo = res.promotionPrice
        if (_promotionInfo) {
          if (_promotionInfo.role === "TRAVELER") {
            _promotionInfo = {
              ...this.package_product_info,
              role: _promotionInfo.role,
              name: _promotionInfo.name,
              code: _promotionInfo.code,
            }
          }

          if (_promotionInfo.openAt && _promotionInfo.closeAt) {
            const openAt = this.dayjs(_promotionInfo.openAt)
            const closeAt = this.dayjs(_promotionInfo.closeAt)
            if (openAt && closeAt) {
              if (!this.dayjs().isBetween(openAt, closeAt, "day", "[]")) {
                alert(this.$t("alert.error_invalid_product_page"))
                let path = "/reservation/package"
                path += `?productId=${this.package_product_info.code}`
                this.$router.replace(path)
                return
              }
            }
          }
        } else {
          if (this.getPackageProductId && !this.getPackageProductId.startsWith("default")) {
            _promotionInfo = {
              ...this.package_product_info,
            }
          }
        }

        this.promotionPriceInfo = _promotionInfo
        this.loading = false
      })
      .catch((e) => {
        console.log(e)
        alert(this.$t("alert.error_page"))
        this.$router.go(-1)
        this.loading = false
      })
      .finally(() => {})
  },

  computed: {
    getPromotionCode() {
      return this.$route.query.packageEvent
    },

    getPackageProductId() {
      return this.$route.query.productId
    },

    title() {
      if (this.promotionPriceInfo) {
        return { name: this.getTitle(this.promotionPriceInfo), secure: true }
      } else {
        if (this.package_product_info) {
          if (!this.package_product_info.code.startsWith("default")) {
            return { name: this.getTitle(this.package_product_info), secure: true }
          } else {
            return { name: "", secure: false }
          }
        } else {
          return { name: "", secure: false }
        }
      }
    },
  },

  methods: {
    ...mapActions(["getPromotionPrice"]),

    getTitle(promotionPriceInfo) {
      let name = promotionPriceInfo && promotionPriceInfo.name
      return name ? `${name} 님의` : ""
    },
  },
}
</script>
<style scoped></style>
