<template>
  <div>
    <!-- 본문 -->
    <div class="py-8">
      <v-sheet class="py-4 px-4 mx-auto rounded-lg text-center" width="80%" outlined>
        <!-- 로고 & 문구 -->
        <div class="d-flex mb-2 mx-auto">
          <v-img height="40" width="140" contain src="@/assets/logo/main.svg"></v-img>
        </div>
        <p class="mb-6 text-subtitle-1 font-weight-medium grey--text text--darken-2">{{ $t("page.login.reset-password") }}</p>

        <v-sheet :width="$vuetify.breakpoint.mobile ? '94%' : 280" class="mx-auto">
          <!-- 이메일 -->
          <v-text-field outlined flat dense v-model="email" autofocus hide-details :placeholder="$t('common.email')" @keyup.enter="submit()"></v-text-field>
          <p class="mt-2 mb-4 text-caption text-center" v-html="$t('page.login.reset-password-text')"></p>

          <p class="mt-2 mb-4 text-center primary--text" style="font-size: 10px" v-html="$t('page.login.reset-password-subtext')"></p>

          <!-- 발송 -->
          <v-btn width="100%" class="font-weight-bold" color="primary" dark large depressed @click="submit()">
            {{ $t("page.login.send-email-btn-title") }}
          </v-btn>
        </v-sheet>

        <!-- 버튼 -->
        <div class="d-flex justify-center mt-4">
          <v-btn class="mr-1" color="grey darken-1" text small to="/auth/login"> {{ $t("page.findId.back-to-login-button-title") }} </v-btn>
          <v-btn color="grey darken-1" text small to="/auth/find/confirm"> {{ $t("page.login.find-id-title") }} </v-btn>
        </div>
      </v-sheet>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    user_id: "",
    email: "",
  }),

  methods: {
    submit() {
      // 이메일로 회원 찾기
      this.$http
        .post("/api/user/select/specific/user_id_and_email", {
          params: {
            email: this.email,
          },
        })
        .then((res) => {
          if (!res.data.length) {
            alert(this.$t("alert.error_not_exists_matched_email"))
          } else {
            if (res.data[0].provider_type !== "normal" || res.data[0].phone === "SNS 로그인") {
              const accountType = res.data[0].provider_type || "SNS"
              alert(accountType + "계정 로그인 사용자 입니다.")
              return
            }
            // 이메일 발송
            this.$http
              .post("/api/email/send/find_password", {
                params: {
                  email: this.email,
                  user_id: res.data[0].user_id,
                },
              })
              .then((res) => {
                if (res.data) {
                  alert(this.$t("alert.success_to_send_email_attached_password"))
                }
              })
              .catch((error) => {
                alert(this.$t("alert.error_page"))
              })
          }
        })
        .catch((e) => {
          alert(this.$t("alert.error_page"))
        })
    },
  },
}
</script>
