<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">국내 결제 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 검색 -->
      <v-sheet outlined class="pa-2 mb-2 d-flex">
        <v-sheet class="d-flex align-center">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="select_list.search_type" v-model="search_type"></v-select>
          </v-sheet>

          <!-- 키워드 -->
          <v-text-field placeholder="검색어를 입력해주세요" outlined dense hide-details v-model.trim="keyword" @keyup.enter="search()"></v-text-field>

          <!-- 제출 버튼 -->
          <v-btn height="40" class="px-6 ml-3" color="primary" dark depressed @click="search()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>
          <div class="ml-10 d-flex align-center">
            <v-menu v-model="startAtMenu" :close-on-content-click="false" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="startAtDate" label="시작일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green" clearable></v-text-field>
              </template>
              <v-date-picker v-model="startAtDate" no-title scrollable color="green" @input="startAtMenu = false"></v-date-picker>
            </v-menu>

            <span class="px-4"> ~ </span>

            <v-menu v-model="endAtMenu" :close-on-content-click="false" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="endAtDate" label="종료일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning" clearable></v-text-field>
              </template>
              <v-date-picker v-model="endAtDate" no-title scrollable color="warning" @input="endAtMenu = false"></v-date-picker>
            </v-menu>
          </div>
        </v-sheet>

        <v-spacer></v-spacer>

        <!-- 새로고침 버튼 -->
        <v-btn height="40" class="px-3 ml-2 my-auto" color="primary" dark depressed @click="refresh()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-sheet>

      <!-- 목록 -->
      <v-radio-group v-model="selected" hide-details>
        <v-data-table
          :items="list"
          hide-default-header
          no-data-text="데이터가 존재하지 않습니다."
          :options.sync="options"
          :server-items-length="totalCount"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
            'show-current-page': true,
            'show-first-last-page': true,
          }"
          :page.sync="pageNum"
        >
          <template v-slot:header>
            <tr>
              <th style="width: 50px; height: 57px">주문번호(토스번호)</th>
              <th style="width: 100px">상품이름</th>
              <th style="width: 40px">결제금액</th>
              <th style="width: 40px">취소가능금액</th>
              <th style="width: 100px">아이디</th>
              <th style="width: 50px">사용자이름</th>
              <th style="width: 40px">상태</th>
              <th style="width: 40px">결제수단</th>
              <th style="width: 50px">승인일시</th>
              <th style="width: 44px">영수증</th>
              <th style="width: 50px">취소내역</th>
              <th style="width: 60px">환불</th>
            </tr>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td class="text-truncate">
                {{ item.toss_id }}
              </td>
              <td class="text-truncate">
                {{ item.orderName }}
              </td>
              <td class="text-truncate">
                {{ $toComma(item.totalAmount || 0) }}
              </td>
              <td class="text-truncate">
                {{ $toComma(item.balanceAmount || 0) }}
              </td>
              <td class="text-truncate">
                {{ item.user_id }}
              </td>
              <td class="text-truncate">
                {{ item.userName }}
              </td>
              <td class="text-truncate">
                {{ item.status }}
              </td>
              <td class="text-truncate">
                {{ item.method }}
              </td>
              <td class="text-truncate">
                {{ dayjs(item.approvedAt).format("M/D HH:mm") }}
              </td>
              <td class="text-truncate">
                <v-btn color="primary" outlined :href="item.receipt" target="_blank" small>영수증</v-btn>
              </td>
              <td class="text-truncate">
                <v-btn v-if="item.cancels" color="primary" outlined @click="openDialog(item)" small>취소내역</v-btn>
              </td>
              <td class="text-truncate">
                <v-btn v-if="item.balanceAmount" class="px-3 ml-3" color="primary" outlined dark @click="openCancelCash(item)" small>
                  <v-icon small left>mdi-credit-card-refund</v-icon>
                  환불하기
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-radio-group>
    </v-sheet>

    <v-dialog v-model="cancelDetail" width="460" content-class="rounded-xl" :key="'cancelDetail-' + openDialogKey">
      <v-card>
        <v-card-title>취소 내역</v-card-title>
        <v-divider></v-divider>
        <v-list two-line v-if="selectedItem.cancelItems">
          <template>
            <v-list-item-content class="px-4" v-for="(cancelItem, index) in selectedItem.cancelItems" :key="index">
              <v-list-item-title class="warning--text text-body-1 font-weight-medium d-flex justify-space-between">
                <span> {{ dayjs(cancelItem.canceledAt).format("M/D HH:mm") }}</span>
                <span> {{ $toComma(cancelItem.cancelAmount) }}원</span>
              </v-list-item-title>
              <v-list-item-title class="text-body-2">
                {{ cancelItem.cancelReason }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn color="pirmay" text @click="cancelDetail = false">닫기</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openCancelDialog" width="460" content-class="rounded-xl" :key="'cancelCash-' + openCancelCashDialogKey" persistent>
      <v-card>
        <v-card-title>환불하기</v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle class="black--text text-body-1 font-weight-medium mt-2">{{ selectedItem.orderName }} 결제 상품 환불</v-card-subtitle>
        <v-card-text class="warning--text">환불 최대금액 {{ $toComma(selectedItem.balanceAmount) }}원</v-card-text>

        <v-card-text>
          <v-text-field label="환불금액" dense outlined type="number" hide-spin-buttons v-model="refundAmount" :rules="[canRefundAmount]" clearable></v-text-field>
          <div class="d-flex justify-end">
            <v-btn color="primary" class="text-center" text @click="refundAmount = selectedItem.balanceAmount">전액</v-btn>
          </div>

          <v-card-subtitle class="info--text text-body-1 font-weight-medium mt-2">환불 사유</v-card-subtitle>
          <v-text-field class="rounded-15" outlined v-model="cancelReason" counter="100" height="100"></v-text-field>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-spacer />
          <v-btn class="px-4" depressed outlined @click="openCancelDialog = false" :disabled="cache_canceling">닫기</v-btn>
          <v-btn class="px-4 mx-4" color="red" dark depressed @click="cancelCashItem" :loading="cache_canceling">환불</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    select_list: {
      search_type: ["주문번호", "아이디", "이름"],
    },
    search_type: "아이디",
    keyword: "",
    startAtMenu: false,
    endAtMenu: false,
    startAtDate: null,
    endAtDate: null,

    loading: false,

    selected: false,
    selectedItem: {},

    pageNum: 1,
    list: [],
    totalCount: 0,

    //pagination
    options: {},

    cancelDetail: false,
    openDialogKey: 0,

    openCancelDialog: false,
    openCancelCashDialogKey: 0,

    cache_canceling: false,
    refundAmount: 0,
    cancelReason: "",
  }),

  watch: {
    options: {
      handler() {
        console.log(this.options)
        this.load(this.options.page, this.options.itemsPerPage)
      },
      deep: true,
    },
  },

  methods: {
    // 목록
    canRefundAmount(value) {
      if (this.selectedItem.balance_amount) {
        if (Number(value) >= 0 && Number(value) <= this.selectedItem.balance_amount) {
          return true
        } else {
          return "금액을 확인하세요"
        }
      } else {
        return "잘못된 항목입니다."
      }
    },

    refresh() {
      this.load(this.options.page, this.options.itemsPerPage)
    },

    search() {
      this.pageNum = 1
      this.load()
    },

    async load(page = 1, itemPerPage = 10) {
      console.log("search:", this.pageNum, page)
      this.loading = true

      try {
        let restApiUrl = "/api/payments/admin/toss"

        let params = {
          limit: itemPerPage,
          offset: (page - 1) * itemPerPage,
          desc: true,
        }

        if (this.keyword && this.search_type === "주문번호") {
          restApiUrl = restApiUrl + `/${this.keyword}`
        } else if (this.keyword && this.search_type === "아이디") {
          params["user_id"] = this.keyword
        } else if (this.keyword && this.search_type === "이름") {
          params["user_name"] = this.keyword
        }

        if (this.startAtDate && this.endAtDate) {
          params["from"] = this.dayjs(this.startAtDate).format("YYYY-MM-DD")
          params["to"] = this.dayjs(this.endAtDate).format("YYYY-MM-DD")
        }

        const result = await this.$http.get(restApiUrl, {
          params,
        })
        this.list = result.data.data
        this.totalCount = result.data.totalCount
        console.log(this.list)
      } catch (e) {
        alert("목록을 가져오지 못했습니다.")
      } finally {
        this.loading = false
      }
    },
    // 결제 취소
    cancelCashItem: _.debounce(async function () {
      console.log("환불 진행")
      if (!this.refundAmount || !this.selectedItem || !this.selectedItem.balance_amount) {
        alert("취소 가능한 금액이 없습니다.")
        return
      }

      if (this.refundAmount > this.selectedItem.balance_amount) {
        alert("취소 가능 금액보다 큽니다. 금액을 다시 입력해 주세요.")
        return
      }

      let confirmMessage = `해당 금액 ${this.refundAmount} 환불을 진행하시겠습니까?`
      if (confirm(confirmMessage)) {
        

        this.cache_canceling = true

        // 시간 체크
        this.$http
          .post("/api/payments/toss/cancel", {
            paymentKey: this.selectedItem.paymentKey,
            cancelAmount: this.refundAmount,
            cancelReason: this.cancelReason || "관리자 취소",
            isAdmin: true,
            adminId: this.$store.state.lafia_admin.user_id,
            isAll: this.selectedItem.balance_amount === this.refundAmount,
          })
          .then((res) => {
            console.log(res)
            if (res.data) {
              alert("정상적으로 환불 처리되었습니다.")
            }
            this.refresh()
          })
          .catch((e) => {
            alert("오류가 발생하여 환불처리가 완료되지 않았습니다. " + e.toString())
            console.log(e)
          })
          .finally(() => {
            this.cache_canceling = false
            this.openCancelDialog = false
          })
      }
    }, 500),

    openDialog(item) {
      this.selectedItem = item

      this.openDialogKey++
      this.cancelDetail = true

      this.selectedItem.cancelItems = []

      let cancels = item.cancels
      if (cancels) {
        //const jsonstr = JSON.stringify(cancels)
        const items = eval(cancels)
        console.log(items)

        this.selectedItem.cancelItems = items
      }
    },

    openCancelCash(item) {
      this.selectedItem = item
      this.refundAmount = 0
      this.openCancelDialog = true
      this.openCancelCashDialogKey++
    },
  },
}
</script>
<style scoped>
.timeTable {
  /* width: 100%;
    max-width: 1030px; */
  width: 100%;
  min-width: 1030px;
  max-width: 1280px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  text-align: center;
  font-weight: 500;
}

/* 상세보기 */
.table_detail {
  background: white;
}

.table_detail tr td {
  padding: 4px;
}

.table_detail tr td:first-child {
  width: 120px;
  padding: 4px;
}
</style>
